<template>
  <div>
    <div class="registration-thankyou">
      <h2 class="registration-thankyou__heading"
        v-html="__('registrations.form.screens.thankyou.heading', {
              'name': name.first_name,
              'eventName': eventEdition.name
            })"
      ></h2>
      <p class="registration--description">
        {{ __('registrations.form.screens.thankyou.sub_heading') }}
      </p>
    </div>
    <RegistrationFooter></RegistrationFooter>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import useRegistrationStore from '~/Store/Registration';
import RegistrationFooter from '~/Components/Frontend/Registration/Screens/RegistrationFooter.vue';
import { store } from '~/Frontend/store';

export default {
  name: 'RegistrationThankyou',
  components: [
    RegistrationFooter,
  ],
  computed: {
    // Store will be accessible as its id + 'Store'
    ...mapStores(useRegistrationStore),
    eventEdition() {
      return store.eventEdition;
    },
    name() {
      return this.registrationStore.getQuestions.find((question) => question.question_type === 'name')?.answer;
    },
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
  },
};
</script>
