<template>
  <div class="match-made-progress">
    <template v-if="progressData.showProgressBar">
      <div v-if="progressData.progressPercentage" class="match-made-progress__line-bar">
        <ProgressBar
          :progress="progressData.progressPercentage"
          :minimum="progressData.minimumPercentage"
          :fill="progressData.progressPercentage <= progressData.minimumPercentage ? 'warning' : 'success'"
          >
        </ProgressBar>
      </div>
      <p v-if="progressData.deadlineDifference" class="match-made-progress__message">{{ progressData.deadlineDifference }}</p>
    </template>
    <template v-else>
      <p v-if="progressData.message" class="match-made-progress__message"><i class="fa-solid fa-circle-info"></i> {{ progressData.message }}</p>
      <p v-if="progressData.deadlineDifference" class="match-made-progress__message">{{ progressData.deadlineDifference }}</p>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { store } from '~/Frontend/store';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      progressData: {},
    };
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
  },
  methods: {
    loadProgressBar() {
      axios.get(this.url)
        .then((response) => {
          this.progressData = response.data.progress_data;
        });
    },
  },
  mounted() {
    this.loadProgressBar();
    this.emitter.on('update-match-made-progress', (response) => {
      this.progressData = response.progress_data;
    });
  },
};
</script>
