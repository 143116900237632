<template>
  <FeLoading v-if="loading" />
  <div class="match-list" v-if="!loading">

    <div class="match-list__group" v-if="matches.length > 0">
      <h3 class="match-list__title">{{matches.length}} {{ __('directory.match_made.matched_contacts') }}</h3>
      <MyMatchRow
        v-for="match in matches"
        :key="match.id"
        :contact="match"
        matched
        @toggleMatch="toggleMatch"
        @toggleBlock="toggleBlock"
      >
      </MyMatchRow>
    </div>

    <div v-else class="match-list__no-results">
      <h3>
        <i class="fa-light fa-circle-check"></i> {{ __('directory.match_made.no_matched_contacts_message') }}
      </h3>
      <a
        href="?initialTab=search"
        class="button button--link"
      >
        {{ __('directory.match_made.start_matching') }}
      </a>
    </div>

    <div class="match-list__group" v-if="matchBlocks.length > 0">
      <h3 class="match-list__title">{{matchBlocks.length}} {{ __('directory.match_made.blocked_contacts') }}</h3>
      <MyMatchRow
        v-for="match in matchBlocks"
        :key="match.id"
        :contact="match"
        blocked
        @toggleMatch="toggleMatch"
        @toggleBlock="toggleBlock"
      >
      </MyMatchRow>
    </div>

    <div v-else class="match-list__no-results">
      <h3>
        <i class="fa-light fa-circle-check"></i> {{ __('directory.match_made.no_blocked_contacts_message') }}
      </h3>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import { store } from '~/Frontend/store';

export default {
  components: {
    FeLoading,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      contacts: [],
      underMatchMadeLimit: false,
    };
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    matches() {
      return this.contacts.filter((contact) => contact.type === 'match');
    },
    matchBlocks() {
      return this.contacts.filter((contact) => contact.type === 'block');
    },
  },
  methods: {
    load() {
      this.loading = true;
      axios.get(this.url)
        .then((response) => {
          this.contacts = response.data.contacts;
          this.underMatchMadeLimit = response.data.under_matchmade_limit;
          this.loading = false;
        });
    },
    toggleMatch(match) {
      axios.post(`/${this.eventEdition.slug}/ez-match/${match.id}/${match.match_made_program_id}/toggle`)
        .then((response) => {
          this.load();
          this.emitter.emit('update-match-made-progress', {
            progress_data: response.data.progress_data,
          });
        });
    },
    toggleBlock(block) {
      axios.post(`/${this.eventEdition.slug}/ez-match-block/${block.id}/${block.match_made_program_id}/toggle`)
        .then((response) => {
          this.load();
          this.emitter.emit('update-match-made-progress', {
            progress_data: response.data.progress_data,
          });
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
