<template>
  <section class="contact-card" @click="goToProfile">
    <div
      :style="{ 'background-image': background }"
      class="contact-card__top-banner">
    </div>

    <div class="contact-card__body" :class="{'contact-card__body--faded': isHidden}" >
      <div
        class="contact-card__contact-type"
        :style="{ 'visibility': model.label ? 'visible' : 'hidden' }"
      >
        {{ model.label ?? ' ' }}
        <div class="contact-card__sub-menu">
          <SubOptions>
            <template v-slot:button="{toggle}">
              <IconButton @click.stop="toggle" small tinted icon="fa-ellipsis" />
            </template>
            <SubOptionFavourite
              :favouritableId="model.id"
              favouritableType="Contact"
              v-model:isFavourited="isFavourited"
            />
            <SubOption v-if="button.type !== 'profile'" :link="`#/contacts/${model.id}/profile`" icon="fas fa-user fa-fw" :label="__('expanded_contact.show.view_profile')" />

            <SubOption
              :icon="button.icon"
              :link="button.href"
              :label="button.label"
              :disabled="button.disabled"
              @clicked="button.action"
              />

            <SubOption
              v-if="
                showMatchButtons &&
                match &&
                !meetingId &&
                !match.is_matched &&
                !match.is_matched_by_other_program &&
                !match.is_blocked &&
                !match.is_blocked_by_other_program
              "
              icon="far fa-ban"
              :label="__('expanded_contact.buttons.block')"
              @clicked="toggleBlock"
            />

            <SubOptionHide
              :hideableId="model.id"
              hideableType="Contact"
              v-model:isHidden="isHidden"
            />
          </SubOptions>
        </div>
      </div>

      <div class="contact-card__avatar-holder">
        <ContactAvatar :contact="model" size="xlarge" />
        <div
          v-if="showMatchButtons"
          class="contact-card__match-flourish-holder"
        >
          <span
            v-if="match.is_blocked || match.is_blocked_by_other_program"
            class="contact-card__flourish flourish flourish--blocked"
          >
            <i class="far fa-ban"></i> {{ __('expanded_contact.buttons.blocked') }}
          </span>

           <span
            v-else-if="match.is_matched || match.is_matched_by_other_program"
            class="contact-card__flourish flourish flourish--matched"
          >
            <i class="far fa-check"></i> {{ __('expanded_contact.buttons.matched') }}
          </span>
        </div>
        <div class="contact-card__flourish-holder">
          <span
            v-if="model.flourish_icon || model.flourish_text"
            class="contact-card__flourish flourish"
          >
            <i v-if="model.flourish_icon" :class="model.flourish_icon"></i>
            &nbsp;{{ model.flourish_text }}
          </span>
        </div>
      </div>
      <div class="contact-card__full-name" :title="nameToShow">
        <i class="fa-2xs fa fa-star" v-if="isFavourited"></i>
        {{ nameToShow }}
      </div>
      <div
        :title="model.job_title"
        class="contact-card__company-position"
      >
        {{ model.job_title }}
      </div>

      <div class="contact-card__company-name">
        <a
          @click.stop
          :href="`#/companies/${model.company.id}`"
          :title="model.company.display_name"
          class="contact-card__company-name-link"
        >
          {{ model.company.display_name }}
        </a>
      </div>

      <div class="contact-card__actions vertical-align">
        <a class="button" :class="button.class" :href="button.href" @click.stop="button.action">
          {{ button.label }}
        </a>
      </div>

    </div>

  </section>
</template>

<script>
import axios from 'axios';
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';
import SubOptionFavourite from '~/Components/Frontend/Profile/Actions/SubOptionFavourite.vue';
import SubOptionHide from '~/Components/Frontend/Profile/Actions/SubOptionHide.vue';
import { store, contactInviteCredits, user } from '../../../Frontend/store';
import ContactAvatar from './Components/ContactAvatar.vue';

export default {
  components: {
    ContactAvatar,
    SubOptions,
    SubOption,
    SubOptionFavourite,
    SubOptionHide,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    showMatchButtons: Boolean,
  },
  data() {
    return {
      contactInviteCredits,
      inviteSent: false,
      pendingSentInviteFromActiveContactExists: false,
      pendingIncomingInviteFromThisContactExists: false,
      meetingId: null,
      inviteId: null,
      isFavourited: this.model.isFavourited,
      isHidden: this.model.isHidden,
      match: this.model.match,
    };
  },
  computed: {
    background() {
      if (this.model.backgroundImage) {
        return `url(${this.model.backgroundImage})`;
      }
      return 'linear-gradient(0deg, var(--brand-primary-color-dark) 0%, var(--brand-primary-color) 100%)';
    },
    nameToShow() {
      return this.model.full_name ? this.model.full_name : this.model.display_name;
    },
    /**
     * Extract button context into computed
     */
    button() {
      if (
        this.showMatchButtons
        && user.activeContact.id !== this.model.id
        && user.activeContact.company_id !== this.model.company.id
        && this.match
        && !this.meetingId
      ) {
        if (this.match.is_matched || this.match.is_matched_by_other_program) {
          return {
            action: () => { this.toggleMatch(); },
            // eslint-disable-next-line no-underscore-dangle
            label: this.__('expanded_contact.buttons.remove'),
            type: 'remove',
            icon: 'far fa-xmark-circle',
            class: `button--gray ${this.match.is_matched_by_other_program && 'button--disabled'}`,
            disabled: this.match.is_matched_by_other_program,
          };
        }

        if (this.match.is_blocked || this.match.is_blocked_by_other_program) {
          return {
            action: () => { this.toggleBlock(); },
            // eslint-disable-next-line no-underscore-dangle
            label: this.__('expanded_contact.buttons.remove'),
            type: 'remove',
            icon: 'far fa-xmark-circle',
            class: `button--gray ${this.match.is_blocked_by_other_program && 'button--disabled'}`,
            disabled: this.match.is_blocked_by_other_program,
          };
        }

        if (!this.match.under_matchmade_limit) {
          return {
            action: () => {},
            // eslint-disable-next-line no-underscore-dangle
            label: this.__('expanded_contact.buttons.match'),
            type: 'blocked',
            icon: 'far fa-plus-circle',
            class: 'button--disabled',
            disabled: true,
          };
        }

        return {
          action: () => { this.toggleMatch(); },
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.buttons.match'),
          type: 'match',
          icon: 'far fa-plus-circle',
          class: 'button--secondary',
          disabled: false,
        };
      }

      if (!this.eventEdition?.enable_meetings) {
        return {
          href: `#/contacts/${this.model.id}/profile`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.view_profile'),
          type: 'profile',
          icon: 'fas fa-user',
          class: 'button--secondary',
          disabled: false,
        };
      }

      if (this.meetingId) {
        return {
          href: `#/meetings/${this.meetingId}/details`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.view_meeting'),
          type: 'view-meeting',
          icon: 'fas fa-eye',
          class: 'button--secondary',
          disabled: false,
        };
      }

      if (this.pendingIncomingInviteFromThisContactExists) {
        return {
          href: `#/invites/${this.model.pendingInvitesReceived[0]}/show`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.respond'),
          type: 'pending-incoming-received',
          icon: 'fas fa-eye',
          class: 'button--secondary',
          disabled: false,
        };
      }

      if (this.pendingSentInviteFromActiveContactExists) {
        return {
          href: `#/contacts/${this.model.id}/conversation`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.view_invite'),
          type: 'pending-sent-invite',
          icon: 'fas fa-eye',
          class: 'button--secondary',
          disabled: false,
        };
      }

      if (this.model.policy.can_meet) {
        return {
          href: `#/contacts/${this.model.id}/send-invite`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.send_invite'),
          type: 'send-invite',
          icon: 'fas fa-envelope-open-text',
          class: 'button--secondary',
          disabled: false,
        };
      }

      if (this.model.policy.can_upgrade_to_meet) {
        return {
          href: `#/purchase-upgrade/${this.model.policy.purchase_item_id}`,
          // eslint-disable-next-line no-underscore-dangle
          label: this.__('expanded_contact.show.send_invite'),
          type: 'send-invite',
          icon: 'fas fa-envelope-open-text',
          class: 'button--secondary',
          disabled: false,
        };
      }

      return {
        href: `#/contacts/${this.model.id}/profile`,
        // eslint-disable-next-line no-underscore-dangle
        label: this.__('expanded_contact.show.view_profile'),
        type: 'profile',
        icon: 'fas fa-user',
        class: 'button--secondary',
        disabled: false,
      };
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
  mounted() {
    this.pendingSentInviteFromActiveContactExists = this.model.pendingInvitesSent.length > 0;
    this.pendingIncomingInviteFromThisContactExists = this.model.pendingInvitesReceived.length > 0;

    // old view model
    if (this.model?.meeting) {
      this.meetingId = this.model.meeting.id;
    }
    // new resource
    if (this.model?.meeting_id) {
      this.meetingId = this.model.meeting_id;
    }

    this.emitter.on('invites.sent', (data) => {
      if (data.contactId === this.model.id) {
        this.pendingSentInviteFromActiveContactExists = true;
      }
    });
    this.emitter.on('invites.accepted', (data) => {
      if (data.attendeeContactIds.includes(this.model.id)) {
        this.meetingId = data.meetingId;
      }
    });
    this.emitter.on('invites.declined', (data) => {
      if (data.inviteSenderId === this.model.id) {
        this.pendingIncomingInviteFromThisContactExists = false;
      }
    });
    this.emitter.on('invites.withdrawn', (data) => {
      if (data.inviteRecipientId === this.model.id) {
        this.pendingSentInviteFromActiveContactExists = false;
      }
    });
    this.emitter.on('invites.forwarded', (data) => {
      if (data.inviteSenderId === this.model.id) {
        this.pendingIncomingInviteFromThisContactExists = false;
      }
    });
    this.emitter.on('meetings.cancelled', (data) => {
      if (data.attendeeContactIds.includes(this.model.id)) {
        this.pendingIncomingInviteFromThisContactExists = false;
        this.meetingId = null;
      }
    });
  },
  methods: {
    toggleMatch() {
      axios.post(`/${this.eventEdition.slug}/ez-match/${this.model.id}/${this.match.match_made_program_id}/toggle`)
        .then((response) => {
          this.match.is_matched = response.data.is_matched;
          this.match.is_blocked = response.data.is_blocked;
          this.emitter.emit('update-match-made-progress', {
            progress_data: response.data.progress_data,
          });
          this.emitter.emit('update-match-made-contacts', {
            under_matchmade_limit: response.data.under_matchmade_limit,
          });
        }).catch((response) => {
          this.errors = response.data?.errors ?? {};
        });
    },
    toggleBlock() {
      axios.post(`/${this.eventEdition.slug}/ez-match-block/${this.model.id}/${this.match.match_made_program_id}/toggle`)
        .then((response) => {
          this.match.is_matched = response.data.is_matched;
          this.match.is_blocked = response.data.is_blocked;
          this.emitter.emit('update-match-made-progress', {
            progress_data: response.data.progress_data,
          });
        }).catch((response) => {
          this.errors = response.data?.errors ?? {};
        });
    },
    goToProfile() {
      window.location.href = `#/contacts/${this.model.id}/profile`;
    },
  },
  watch: {
    'model.match': function (newValue) {
      this.match = newValue;
    },
  },
};
</script>
