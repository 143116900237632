export default {
  'assets.image.image': 'Imagem',
  'assets.image.name': 'Título da imagem',
  'assets.video.title': 'Título do Vídeo',
  'assets.video.title.default': 'Vídeo sem nome',
  'assets.video.video': 'Vídeo',
  'buttons.add': 'Adicionar',
  'buttons.addMore': 'Adicionar mais',
  'buttons.back': 'Atrás',
  'buttons.cancel': 'Cancelar',
  'buttons.change': 'Mudar',
  'buttons.clear': 'Apagar',
  'buttons.close': 'Fechar',
  'buttons.contact': 'Contato',
  'buttons.delete': 'Apagar',
  'buttons.done': 'Feito',
  'buttons.edit': 'Editar',
  'buttons.help': 'Ajuda',
  'buttons.impersonateUser': 'Conetar como',
  'buttons.manage': 'Administar',
  'buttons.manageImages': 'Administrar imagens',
  'buttons.new': 'Novo',
  'buttons.next': 'Próximo',
  'buttons.return': 'Voltar',
  'buttons.save': 'Salvar',
  'buttons.sendLink': 'Enviar link',
  'buttons.set': 'Definir',
  'buttons.set_password': 'Definir senha',
  'buttons.update': 'Atualizar',
  'buttons.uploadImage': 'Carregar imagem',
  'buttons.locked': 'Bloqueado',
  'buttons.write_with_ai': 'Escreva com IA',
  'buttons.recreate_with_ai': 'Recriar com IA',
  'buttons.notifications': 'Notificações',
  'buttons.run_search': 'Executar',
  'buttons.show_more': 'Mostrar mais',
  'buttons.show_less': 'Mostrar menos',
  'action.unfavourite': 'Remover dos favoritos',
  'action.favourite': 'Favorito',
  'action.unhide': 'Mostrar',
  'action.hide': 'Esconder',
  'catalogue.catalogueEntry.description': 'Descriçāo',
  'catalogue.catalogueEntry.gallery': 'Imagens',
  'catalogue.catalogueEntry.highlights': 'Destaques',
  'catalogue.catalogueEntry.image': 'Imagem',
  'catalogue.catalogueEntry.keywords': 'Palavras chave',
  'catalogue.catalogueEntry.links': 'Links',
  'catalogue.catalogueEntry.moreOptions': 'Mais opções',
  'catalogue.catalogueEntry.name': 'Título',
  'catalogue_product_form.add_image_success': 'Você adicionou uma imagem ao :filename com sucesso, vai ser visível quando atualizar a página.',
  'catalogue_product_form.delete': 'Apagar',
  'catalogue_product_form.description': 'Descriçāo*:',
  'catalogue_product_form.edit': 'Editar',
  'catalogue_product_form.highlights.add': 'Adicionar destaque',
  'catalogue_product_form.highlights.no_highlights': 'Sem destaques adicionados',
  'catalogue_product_form.highlights.placeholder': '',
  'catalogue_product_form.highlights.title': 'Destaques',
  'catalogue_product_form.image': 'Imagem do Produto*',
  'catalogue_product_form.images': 'Imagens',
  'catalogue_product_form.keywords': 'Palavras Chave',
  'catalogue_product_form.keywords_descriptions': "Separe várias palavras-chave com uma virgula ','",
  'catalogue_product_form.name': 'Nome*:',
  'catalogue_product_form.order': 'Ordem:',
  'catalogue_product_form.order_description': 'Ordem ascendente (1 estará listado antes do 2).',
  'catalogue_product_form.recommended_image_size': 'Recomendamos 1280px por 720px (imagens vāo ser mostradas num rácio 16:9 ). Tamanho máximo do ficheiro 5Mb',
  'catalogue_product_form.remove_image_success': 'Você adicionou uma imagem ao :filename com sucesso, vai ser visível quando atualizar a página.',
  'catalogue_product_form.save': 'Salvar',
  'catalogue_product_form.save_then_upload': 'Por favor salve e depois clique em editar para carregar as imagens.',
  'catalogue_product_form.subtitle': 'Subtítulo*:',
  'catalogue_product_form.tagline': 'Slogan*:',
  'catalogue_product_form.title.create': 'Criar {{ type }}',
  'catalogue_product_form.title.update': 'Atualizar {{ type }}',
  'catalogue_product_form.update': 'Atualize',
  'catalogue_product_form.update_photo': 'Atualize imagem',
  'catalogue_product_form.upload_photo': 'Carregue imagem',
  'catalogue_product_preview.buttons.view': 'Visualizar produto',
  'catalogue_product_preview.description': 'Descriçāo',
  'catalogue_product_preview.highlights': 'Destaques',
  'catalogue_product_preview.learn_more': 'Saiba mais',
  'catalogue_product_preview.more': 'Mais',
  'catalogue_product_preview.read_more': 'Consulte mais informaçāo',
  'catalogue_product_preview.show_less': 'Mostrar menos',
  'catalogue_product_preview.view_product': 'Ver produto',
  'company.actions.business_card_dropped': 'O seu cartāo de visita foi compartilhado',
  'company.actions.drop_business_card': 'Enviar cartāo de visita',
  'company.company_book_meeting_modal.cancel': 'Cancelar',
  'company.company_book_meeting_modal.cannot_meet': 'Você não pode se conectar com nenhum contato desta empresa',
  'company.company_book_meeting_modal.meet': 'Conecte-se',
  'company.company_book_meeting_modal.more': 'Mais',
  'company.company_book_meeting_modal.next': 'Próximo →',
  'company.company_book_meeting_modal.other_contacts': 'Outros contatos',
  'company.company_book_meeting_modal.recommended_contacts': 'Recomendados',
  'company.company_book_meeting_modal.select_contact': 'Select a contact to meet:',
  'company.company_book_meeting_modal.title': 'Agendar reuniāo',
  'company.company_preview_modal.activities': 'Atividades e Interesses',
  'company.company_preview_modal.book_meeting': 'Agendar reuniāo',
  'company.company_preview_modal.company_profile': 'Perfil da Empresa',
  'company.company_preview_modal.people': 'Equipe',
  'company.company_preview_modal.read_more': 'Visualizar mais',
  'company.company_preview_modal.title': '{{ company_display_name }}',
  'company.company_preview_modal.view_people': 'Visualizar todos os contatos →',
  'company.company_preview_modal.view_profile_page': 'Visualizar a página de perfil',
  'company.drop_business_card.agreement': 'Eu estou por este meio permitindo que o meu <strong> nome, cargo/funçāo e endereço de e-mail </strong> seja compartilhado com {{ company_display_name }}',
  'company.drop_business_card.cancel': 'Cancelar',
  'company.drop_business_card.disclaimer': 'Eu compreendo que a minha informaçāo vai ser armazenada por {{ company_display_name }} e que preciso contatar {{ company_display_name }} para alterar as minhas preferências de comunicaçāo.',
  'company.drop_business_card.header': 'Compartilhar a sua informação de contato',
  'company.drop_business_card.share': 'Eu concordo',
  'company.drop_business_card.share_success.done': 'Feito',
  'company.drop_business_card.share_success.message': 'O seu nome, cargo/funçāo, endereço de e-mail e número de telefone foram compartilhados com {{ company_display_name }}',
  'company.drop_business_card.share_success.title': 'Informações compartilhadas',
  'company_card.view_company': 'Visualizar empresa',
  'concierge_modal.info_text': 'Contatar a equipe de concierge para questões relacionadas com reuniões e ajuda.',
  'concierge_modal.link_text': 'Precisa de ajuda?',
  'concierge_modal.whatsapp_button': 'Bate-papo no WhatsApp',

  // Conference sessions
  'conference_sessions.ask_a_question.box_closing': 'Esta caixa vai fechar automaticamente.',
  'conference_sessions.ask_a_question.buttons.ask': 'Coloque uma questāo',
  'conference_sessions.ask_a_question.buttons.cancel': 'Cancelar',
  'conference_sessions.ask_a_question.buttons.send': 'Enviar',
  'conference_sessions.ask_a_question.description': 'O moderador desta sessāo irá rever a sua questāo',
  'conference_sessions.ask_a_question.header': 'Coloque uma questāo',
  'conference_sessions.ask_a_question.placeholder': 'A minha questāo...',
  'conference_sessions.ask_a_question.question_submitted': 'A sua questāo foi submetida.',
  'conference_sessions.preview_session.speakers': 'Oradores',
  'conference_sessions.preview_session.title': 'Sessāo da palestra',
  'conference_sessions.row.buttons.add_to_schedule': 'Adicionar à agenda',
  'conference_sessions.row.buttons.join_session': 'Participar da sessāo',
  'conference_sessions.row.buttons.more': 'Mais',
  'conference_sessions.row.buttons.preview_session': 'Pre-visualizaçāo',
  'conference_sessions.row.buttons.remove_from_schedule': 'Remover da agenda',
  'conference_sessions.row.buttons.schedule_clash': 'Conflito de agendas',
  'conference_sessions.row.buttons.booking_capacity_full': 'Capacidade cheia',
  'conference_sessions.row.buttons.watch_now': 'Assistir agora',
  'conference_sessions.row.session': 'Sessāo',
  'conference_sessions.row.sponsored_by': 'Patrocinado por',
  'conference_sessions.row.starting_soon': 'Começa brevemente',

  'conversations.accepted_by': 'Convite de reuniāo aceite por {{ sender }}',
  'conversations.buttons.send_invite': 'Enviar convite',
  'conversations.cancelled_by': 'Convite de reuniāo cancelado por {{ sender }}',
  'conversations.chips.cancelled': 'Reuniāo cancelada',
  'conversations.chips.colleague_added': 'Colega de equipe adicionado',
  'conversations.chips.confirmed': 'Reuniāo confirmada',
  'conversations.chips.declined': 'Convite de reuniāo rejeitado',
  'conversations.chips.forwarded': 'Convite de reuniāo reencaminhado',
  'conversations.chips.new_invite': 'Convite de reuniāo',
  'conversations.chips.updated': 'Reuniāo atualizada',
  'conversations.chips.withdrawn': 'Cancelar convite de reuniāo',
  'conversations.colleague_added': '{{ added_by }} adicionou {{ new_colleague }} à reuniāo.',
  'conversations.declined_by': 'Convite de reuniāo recusado por {{ sender }}',
  'conversations.forwarded_to': 'Convite de reuniāo reencaminhado para {{ recipient }}',
  'conversations.invitation_from': 'Convite de reuniāo de {{ sender }}',
  'conversations.view_meeting': 'Visualizar Reuniāo',
  'conversations.withdrawn_by': 'Convite de reuniāo removida por {{ sender }}',
  'countdown.days': 'dias',
  'countdown.hours': 'horas',
  'countdown.minutes': 'minutos',
  'countdown.seconds': 'segundos',
  'crm.communicationPreference.channels.email': 'Notificaçāo e lembretes por email',
  'crm.communicationPreference.channels.phone': 'Suporte e comunicaçāo por telefone',
  'crm.communicationPreference.channels.sms': 'Suporte e comunicaçāo por SMS',
  'crm.communicationPreference.name': 'Preferências',
  'crm.company.bio': 'Descriçāo da empresa',
  'crm.company.displayName': 'Nome de apresentaçāo',
  'crm.company.legalName': 'Nome legal',
  'crm.company.logo': 'Logotipo da empresa',
  'crm.company.officeLocation': 'Localizaçāo',
  'crm.company.officeLocations': 'Localizações',
  'crm.company.socials': 'Redes Sociais',
  'crm.company.tagline': 'Curta introduçāo',
  'crm.company.websites': 'Websites',
  'crm.companyOfficeLocation.address': 'Endereço',
  'crm.companyOfficeLocation.country': 'País',
  'crm.contact.avatar': 'Foto de perfil',
  'crm.contact.bio': 'Sobre mim',
  'crm.contact.country': 'País',
  'crm.contact.email': 'Email',
  'crm.contact.firstName': 'Primeiro nome',
  'crm.contact.fullName': 'Nome',
  'crm.contact.jobTitle': 'Cargo na empresa',
  'crm.contact.lastName': 'Último nome',
  'crm.contact.lookingFor': 'Procurando',
  'crm.contact.managedBy': 'Administradores de perfil',
  'crm.contact.optouts': 'Desativar participaçāo',
  'crm.contact.timezone': 'Fuso horário',
  'crm.contact.title': 'Título',
  'crm.link.type.website': 'Website',
  'crm.link.url': 'Url',
  'crm.phone.types.mobile': 'Número de celular',
  'crm.phone.types.office': 'Número de telefone do escritório',

  'directory.default_search_name': '{{ date }} Resultados da pesquisa',
  'directory.saved_search_success': 'Pesquisa salva com sucesso.',
  'directory.updated_search_success': 'Pesquisa atualizada com sucesso.',
  'directory.edit_search': 'Editar esta pesquisa',
  'directory.export_wishlist': 'Baixar',
  'directory.filters.apply': 'Aplicar',
  'directory.filters.close': 'Remover filtros',
  'directory.filters.filters': 'Classifique e filtros',
  'directory.filters.open': 'Classifique e filtros',
  'directory.filters.primary.select_one_option': 'Selecione <strong>uma</strong> opçāo:',
  'directory.filters.reset': 'Redefinir',
  'directory.noFavourites.message': 'Você ainda não tem favoritos.',
  'directory.noFavourites.title': 'Sem favoritos',
  'directory.noRecommendations.message': 'Nenhum resultado para exibir.',
  'directory.noRecommendations.title': 'Nenhum resultado',
  'directory.noResults.message': 'Nāo conseguimos encontrar nenhum resultado, tente redefenir a sua busca',
  'directory.noResults.title': 'Sem resultados',
  'directory.pagination.load_more': 'Ver mais resultados',
  'directory.notification_frequency': 'Notificar-me sobre novos resultados de pesquisa',
  'directory.save_search': 'Salvar pesquisa',
  'directory.saved_search_card_error': 'Algo deu errado, por favor atualize seu navegador',
  'directory.search_name': 'Nome da pesquisa',
  'directory.search_last_run': 'Última execução: {{ date }}',
  'directory.text_search': 'Pesquisa: {{ value }}',
  'directory.initial_search': 'Inicial: {{ value }}',
  'directory.noSavedSearches.message': 'Nenhuma pesquisa salva. Comece a <a href="?initialTab=search">pesquisar ...</a>',
  'directory.match_made.no_blocked_contacts_message': 'Você não tem contatos bloqueados',
  'directory.match_made.no_matched_contacts_message': 'Você não tem contatos correspondentes',
  'directory.match_made.blocked_contacts': 'Contatos bloqueados',
  'directory.match_made.matched_contacts': 'Contatos correspondentes',
  'directory.match_made.view_profile': 'Ver perfil',
  'directory.match_made.remove_block': 'Remover bloqueio',
  'directory.match_made.convert_to_match': 'Converter em correspondência',
  'directory.match_made.remove_match': 'Remover correspondência',
  'directory.match_made.convert_to_block': 'Converter em bloqueio',
  'directory.match_made.start_matching': 'Iniciar correspondência',

  'drop_image.buttons.cancel': 'Cancelar',
  'drop_image.buttons.clear': 'Apagar',
  'drop_image.buttons.remove': 'Remover',
  'drop_image.buttons.submit': 'Enviar',
  'drop_image.preview_text': 'Pre-visualizaçāo de {{ image_name }}',
  'drop_image.text': 'Arraste a imagem ou clique aqui',
  'event_editions.card.coming_soon': 'Brevemente',
  'event_editions.card.event_closed': 'Evento Terminado',
  'event_editions.card.launch': 'Lançamento do Evento',
  'event_editions.card.notify_me': 'Notifique-me',
  'event_editions.card.reg_done': 'Você registrou-se para este evento',
  'event_editions.card.reg_open': 'Registro aberto',
  'event_editions.card.reg_soon': 'Registro começa em breve',
  'event_editions.card.register': 'Registre-se agora',
  'expanded_contact.buttons.accept_invite': 'Aceitar',
  'expanded_contact.buttons.book_meeting': 'Marcar reuniāo',
  'expanded_contact.buttons.decline_invite': 'Recusar',
  'expanded_contact.buttons.forward_to_colleague': 'Encaminhar',
  'expanded_contact.buttons.withdraw_invite': 'Remover pedido',
  'expanded_contact.buttons.match': 'Combinar',
  'expanded_contact.buttons.remove': 'Remover',
  'expanded_contact.buttons.block': 'Bloquear',
  'expanded_contact.buttons.blocked': 'Bloqueado',
  'expanded_contact.buttons.matched': 'Correspondente',
  'expanded_contact.meeting.addColleague.button': 'Adicionar colegas de equipe',
  'expanded_contact.meeting.addColleague.noColleagues': 'Nāo há colegas de equipe disponíveis',
  'expanded_contact.meeting.addColleague.subheader': 'Adicionar colegas de equipe',
  'expanded_contact.meeting.back': 'Voltar aos detalhes de reuniāo',
  'expanded_contact.meeting.cancel.button': 'Enviar Cancelamento',
  'expanded_contact.meeting.cancel.subheader': 'Nāo estou disponível para participar nesta reuniāo',
  'expanded_contact.meeting.cancelledHeader': 'Reuniāo cancelada',
  'expanded_contact.meeting.contactNumber': 'Número de celular: ',
  'expanded_contact.meeting.forwardToColleague.button': 'Selecionar colega de equipe',
  'expanded_contact.meeting.forwardToColleague.subheader': 'Encaminhar reuniāo para um colega de equipe',
  'expanded_contact.meeting.informationHeader': 'Informaçāo de reuniāo',
  'expanded_contact.meeting.informationSubheader': 'Nós iremos enviar um lembrete por SMS 10 minutos antes da reuniāo começar.',
  'expanded_contact.meeting.inviteColleagues.button': 'Convidar colegas',
  'expanded_contact.meeting.inviteColleagues.subheader': 'Encaminhar reuniāo a um colega de equipe',
  'expanded_contact.meeting.missingContactNumber': 'Por favor adicione um número de celular',
  'expanded_contact.meeting.participants': 'Participantes',
  'expanded_contact.meeting.reschedule.button': 'Reagendar reuniāo',
  'expanded_contact.meeting.reschedule.subheader': 'Mudar data, hora e localizaçāo de reuniāo ',
  'expanded_contact.show.about.company.title': 'Informaçāo da empresa',
  'expanded_contact.show.about.contact.country': 'País',
  'expanded_contact.show.about.contact.looking_for': 'Buscando',
  'expanded_contact.show.about.contact.title': 'Sobre mim',
  'expanded_contact.show.colleagues': 'Colegas de equipe',
  'expanded_contact.show.errors.cannot_load': 'Nāo conseguimos carregar a informaçāo do contato.',
  'expanded_contact.show.other_contacts': 'Outros contatos de {{ company_display_name }}',
  'expanded_contact.show.respond': 'Responder',
  'expanded_contact.show.send_invite': 'Enviar convite',
  'expanded_contact.show.view_invite': 'Visualizar Convite',
  'expanded_contact.show.view_meeting': 'Visualizar Reuniāo',
  'expanded_contact.show.view_profile': 'Visualizar perfil',

  // Exclusive events
  'exclusive_events.no_description': 'Não há descrição para este evento.',
  'exclusive_events.row.buttons.booking_capacity_full': 'Capacidade cheia',
  'exclusive_events.row.buttons.booking_add_to_schedule': 'Adicionar à programação',
  'exclusive_events.row.buttons.booking_schedule_clash': 'Agendar confronto',
  'exclusive_events.row.buttons.booking_remove_from_schedule': 'Remover da programação',

  'filter_modal.cancel': 'Cancelar',
  'filter_modal.save_update': 'Salvar & atualizar',
  'form.image.changeTip': '<a href=\'#\'>Clique para mudar</a> ou arraste e solte. <br/>',
  'form.image.maxSize': 'Tamanho máximo do ficheiro de 5MB',
  'form.image.tip': '<a href=\'#\'>Clique para carregar ficheiro/a> ou arraste e solte. <br/>',
  'form.image.unsupportedFileType': 'Tipo de ficheiro inválido.  Utilizar jpg, jpeg, png ou webp.',
  'form.image.recommended_size': 'Tamanho recomendado: {{recommended_size}}',
  'form.phone.placeholder': 'Introduza o seu número de telefone',
  'form.video.tip': '<a href=\'#\'>Clique para carregar ficheiro</a> ou arraste e solte.<br/>',
  'forms.select_all': '(todos)',
  'general.unknown_error': 'Desculpe, algo deu errado. Por favor atualize a página no seu browser e tente de novo',
  'inbox.header': 'Caixa de Entrada',
  'inbox.invites.buttons.accept': 'Aceitar',
  'inbox.invites.buttons.askQuestion': 'Enviar mensagem',
  'inbox.invites.buttons.decline': 'Recusar',
  'inbox.invites.buttons.forward': 'Encaminhar',
  'inbox.invites.buttons.moreOptions': 'Mais opções ...',
  'inbox.invites.buttons.next_invite': 'Próximo convite',
  'inbox.invites.buttons.read_more': 'Mais',
  'inbox.invites.buttons.respond': 'Responder',
  'inbox.invites.buttons.show_less': 'Mostrar menos',
  'inbox.invites.buttons.view': 'Visualizar',
  'inbox.invites.buttons.viewProfile': 'Visualizar perfil',
  'inbox.invites.buttons.withdraw': 'Retirar',
  'inbox.invites.declinedByActiveUser': 'Você rejeitou este convite de reuniāo {{ declined_time }}',
  'inbox.invites.declinedByRecipient': '{{ declined_by }} rejeitou este convite de reuniāo {{ declined_time }}',
  'inbox.invites.expiry.expired': 'Expirado',
  'inbox.invites.expiry.expires': 'Expira',
  'inbox.invites.expiry.expiresSoon': 'Você tem convites de reuniāo expirando em breve.',
  'inbox.invites.expiry.whenExpired': 'Você tem convites de reunião expirados.',
  'inbox.invites.findPeople': 'Busca',
  'inbox.invites.forwardedByActiveUser': 'Você encaminhou este convite de reuniāo para {{ forwardee }}',
  'inbox.invites.forwardedByColleague': 'Reencaminhado para você por {{ forwarded_by }}',
  'inbox.invites.forwardedByRecipient': 'Reencaminhado para{{ forwardee }} por {{ forwarded_by }}',
  'inbox.invites.info.sent_message_concierge': 'A equipe de reuniões irá contatar este usuário se eles nāo responderem brevemente.',
  'inbox.invites.noResults.message': '<a href="{{ findPeopleUrl }}">Busca</a> para novas oportunidades de reuniāo.',
  'inbox.invites.noResults.title': 'Nāo tem convites de reuniāo',
  'inbox.invites.sort.newest': 'Mais recente',
  'inbox.invites.sort.oldest': 'Mais antigo',
  'inbox.invites.sort.placeholder': 'Ordenar',
  'inbox.invites.statuses.accepted': 'Aceite',
  'inbox.invites.statuses.declined': 'Recusado',
  'inbox.invites.statuses.expired': 'Expirado',
  'inbox.invites.statuses.forwarded': 'Encaminhado',
  'inbox.invites.statuses.pending': 'Esperando resposta',
  'inbox.invites.statuses.sent': 'Enviado',
  'inbox.invites.statuses.unread': 'Nova mensagem',
  'inbox.invites.statuses.withdrawn': 'Convites retirados',
  'inbox.invites.statusFilters.accepted': 'Aceite',
  'inbox.invites.statusFilters.declined': 'Recusado',
  'inbox.invites.statusFilters.expired': 'Expirado',
  'inbox.invites.statusFilters.forwarded': 'Encaminhado',
  'inbox.invites.statusFilters.pending': 'Açāo necessária',
  'inbox.invites.statusFilters.sent': 'Enviado',
  'inbox.invites.statusFilters.withdrawn': 'Convites retirados',
  'inbox.invites.whenReceived': 'Recebido',
  'inbox.invites.whenSent': 'Enviado',
  'inbox.invites.withdrawnByActiveUser': 'Você cancelou este convite de reuniāo {{ withdrawn_time }}',
  'inbox.invites.withdrawnBySender': '{{ withdrawn_by }} cancelou este convite {{ withdrawn_time }}',
  'inbox.loading': 'Carregando...',
  'inbox.meetings.buttons.addColleagues': 'Convidar colegas de equipe',
  'inbox.meetings.buttons.cancel': 'Cancelar reuniāo',
  'inbox.meetings.buttons.moreOptions': 'Mais opções ...',
  'inbox.meetings.buttons.rescheduleMeeting': 'Reagendar reuniāo',
  'inbox.meetings.cancelledBy': '{{ cancelled_by }} cancelou esta reuniāo {{ cancel_time }}',
  'inbox.meetings.cancelledByActiveUser': 'Você cancelou esta reuniāo {{ cancel_time }}',
  'inbox.meetings.filters.showCancelled': 'Mostrar reuniões canceladas',
  'inbox.meetings.meetingsToRate_one': 'Você tem uma reuniāo para avaliar. <a href="#/meetings/{{ nextId }}/rate">Avalie agora</a>',
  'inbox.meetings.meetingsToRate_other': 'Você tem {{ count }} reuniões para avaliar. <a href="#/meetings/{{ nextId }}/rate">Avalie agora</a>',
  'inbox.meetings.noResults.message': '<a href="{{ findPeopleUrl }}">Busca</a> para novas oportunidades de reuniāo.',
  'inbox.meetings.noResults.title': 'Você ainda nāo tem reuniões',
  'inbox.meetings.rate': 'Avalie reuniāo',
  'inbox.meetings.statuses.cancelled': 'Cancelado',
  'inbox.meetings.statuses.confirmed': 'Reuniāo confirmada',
  'inbox.meetings.view': 'Detalhes',
  'inbox.messages.info.moreMessagesInThread': 'Há mais mensagens nesta conversa',
  'inbox.messages.info.newUnreadMessage': 'Nova mensagem de chat. <a href="{{url}}">Reply</a>',
  'inbox.messages.meetingInformation': 'Informaçāo da reuniāo',
  'inbox.messages.noResults.message': 'Nāo tem mensagens.',
  'inbox.messages.noResults.title': 'Nāo há mensagens',
  'inbox.messages.sort.newest': 'Mais recente',
  'inbox.messages.sort.oldest': 'Mais antigo',
  'inbox.messages.statusFilters.all': 'Todos',
  'inbox.messages.statusFilters.unread': 'Nāo lidos',
  'inbox.messages.whenReceived': 'Recebido',
  'inbox.messages.whenSent': 'Enviado',
  'inbox.nextPage': 'Próxima página',
  'inbox.previousPage': 'Página anterior',
  'inbox.relationshipLabels.from': 'De:',
  'inbox.relationshipLabels.to': 'Para:',
  'inbox.relationshipLabels.with': 'Com:',
  'inbox.subtitle.meetings': 'Reuniões confirmadas',
  'inbox.subtitle.messages': 'Mensagens',
  'inbox.subtitle.null': ' ',
  'inbox.subtitle.receivedInvites': 'Convites recebidos',
  'inbox.subtitle.sentInvites': 'Convites enviados',
  'meeting.add_colleagues.buttons.add': 'Adicionar colega de equipe',
  'meeting.add_colleagues.buttons.cancel': 'Cancelar',
  'meeting.add_colleagues.buttons.done': 'Feito',
  'meeting.add_colleagues.buttons.invite_more': 'Convidar mais',
  'meeting.add_colleagues.header': 'Adicionar colegas',
  'meeting.add_colleagues.no_colleagues': 'Nāo existem colegas de equipe disponíveis para adicionar',
  'meeting.add_colleagues.subheader': 'Adicionar colegas de equipe à sua reuniāo com <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.add_colleagues.success.message_one': 'Colega de equipe foi adicionado a esta reuniāo com sucesso.',
  'meeting.add_colleagues.success.message_other': 'Colegas de equipe foram adicionados a esta reuniāo com sucesso.',
  'meeting.add_colleagues.success.title_one': 'Colega de equipe adicionado',
  'meeting.add_colleagues.success.title_other': 'Colegas de equipe adicionados',
  'meeting.addColleague.button': 'Adicionar colegas',
  'meeting.addToCalendar': 'Adicionar ao calendário',
  'meeting.attendees': 'Você e {{ displayName }}',
  'meeting.attendeesCount': '{{count}} participantes',
  'meeting.attendeesTitle': 'Participantes',
  'meeting.back_to_meeting': 'Voltar à reuniāo',
  'meeting.cancel.button': 'Enviar Cancelamento',
  'meeting.cancel.cannotAttend': 'Já nāo me encontro disponível para atender esta reuniāo',
  'meeting.cancel.keep': 'Manter reuniāo',
  'meeting.cancel.messageLabel': 'Nota',
  'meeting.cancel.messagePlaceholder': 'Infelizmente, terei que cancelar esta reuniāo porque ...',
  'meeting.cancel.onlyOrganiserCanCancel': 'Só o organizador pode cancelar a reuniāo',
  'meeting.cancel.reasonLabel': 'Motivo para cancelamento',
  'meeting.cancel.reasonPlaceholder': 'Selecionar opçāo...',
  'meeting.cancel.subheader': 'Cancelar a sua reuniāo agendada com <strong> {{ displayName }}, {{ jobTitle }}</strong> de <a href="{{ companyUrl }}"> {{ companyDisplayName }} </a>.',
  'meeting.cancel.title': 'Cancelar Reuniāo',
  'meeting.cancellation_success.message': 'Você cancelou esta reuniāo com <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting.cancellation_success.title': 'Convite recusado',
  'meeting.cancelledBy': 'Esta reuniāo foi cancelada por {{ cancelledBy_display_name }} {{cancelled_at_time}}',
  'meeting.close': 'Fechar',
  'meeting.countdown_minutes': 'minutos, ',
  'meeting.countdown_seconds': 'segundos',
  'meeting.errors.cannot_load': 'Reuniāo nāo carregou.',
  'meeting.finished': 'Reuniāo terminou.',
  'meeting.forward.button': 'Selecionar colega de equipe',
  'meeting.forward.subheader': 'Encaminhar reuniāo para um colega de equipe',
  'meeting.forward.title': 'Encaminhar para o colega de equipe',
  'meeting.in_progress': 'Reuniāo em progresso.',
  'meeting.join_now': 'Entrar agora',
  'meeting.meeting_confirmed.invite_colleagues': 'Convidar colegas de equipe',
  'meeting.meeting_confirmed.message': 'O seu parceiro de reuniāo foi notificado',
  'meeting.meeting_confirmed.next_invite': 'Próximo convite',
  'meeting.meeting_confirmed.title': 'Reuniāo confirmada',
  'meeting.meeting_confirmed.view_meeting': 'Visualizar detalhes',
  'meeting.meeting_starts_in_countdown': 'Reuniāo começa em',
  'meeting.meetingCancelled': 'Reuniāo cancelada',
  'meeting.meetingConfirmed': 'Confirmado por todas as partes.',
  'meeting.mobileNumberLabel': 'Número de celular:',
  'meeting.panelTitle.cancelled': 'Cancelar reuniāo',
  'meeting.panelTitle.information': 'Informaçāo da reuniāo',
  'meeting.phoneNumberMissing': '<a href="{{ updatePhoneUrl }}">Por favor adicionar um número de celular</a>',
  'meeting.rate_meeting.back_to_meeting': 'Voltar à reuniāo',
  'meeting.rate_meeting.buttons.cancel': 'Cancelar',
  'meeting.rate_meeting.buttons.done': 'Feito',
  'meeting.rate_meeting.buttons.next': 'Próximo',
  'meeting.rate_meeting.buttons.rate': 'Avaliar',
  'meeting.rate_meeting.buttons.save': 'Salvar Comentários',
  'meeting.rate_meeting.errors.already_rated': 'Esta reuniāo já foi avaliada',
  'meeting.rate_meeting.fields.feedback.label': 'Comentários sobre a reuniāo (opcional)',
  'meeting.rate_meeting.fields.meeting_did_not_happen.label': 'Reuniāo nāo aconteceu',
  'meeting.rate_meeting.fields.rating.label': 'Availar a sua reuniāo',
  'meeting.rate_meeting.header': 'Avaliar a sua reuniāo',
  'meeting.rate_meeting.subheader': 'Como foi a sua reuniāo com <strong>{{display_name}}, {{job_title}}</strong> de {{company_display_name}}?',
  'meeting.rate_meeting.success.title': 'Avaliaçāo de reuniāo submetida',
  'meeting.reminder': 'Nós iremos enviar um lembrete de SMS 10 minutos antes da reuniāo começar.',
  'meeting.reschedule.back_to_meeting': 'Voltar à reuniāo',
  'meeting.reschedule.button': 'Reagendar reuniāo',
  'meeting.reschedule.onlyOrganiserCanReschedule': 'Só o organizador pode reagendar a reuniāo',
  'meeting.reschedule.subheader': 'Mudar data, hora e localizaçāo da reuniāo',
  'meeting.reschedule.title': 'Reagendar reuniāo',
  'meeting.source_types.auto_accepted': 'Aceitar automaticamente',
  'meeting.source_types.ez_match': 'Ez Match',
  'meeting.source_types.concierge_booked': 'Concierge',
  'meeting.source_types.walk_up': 'Reuniāo planejada no local',
  'meeting.stand': 'Estande {{ stand_number }}',
  'meeting.startingTime': 'Reuniāo começa {{ time_until_meeting }}',
  'meeting.statuses.cancelled': 'Cancelar',
  'meeting.updatePhone': 'Atualizar número',
  'meeting_invite.back_to_conversation': 'Voltar à conversaçāo',
  'meeting_invite.decline.buttons.cancel': 'Cancelar',
  'meeting_invite.decline.buttons.decline': 'Recusar convite de reuniāo',
  'meeting_invite.decline.buttons.done': 'Feito',
  'meeting_invite.decline.errors.cannot_load': 'Convite nāo foi carregado.',
  'meeting_invite.decline.header': 'Rejeitar convite de reuniāo',
  'meeting_invite.decline.inputs.message.label': 'Adicionar uma mensagem',
  'meeting_invite.decline.inputs.reason.label': 'Motivo de rejeiçāo',
  'meeting_invite.decline.inputs.reason.placeholder': 'Selecionar motivo',
  'meeting_invite.decline.subheader': 'Rejeitar este convite de reuniāo de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.message': 'Você rejeitou um convite de reuniāo com sucesso de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.decline.success.title': 'Convite rejeitado',
  'meeting_invite.forward_invite.buttons.cancel': 'Manter convite',
  'meeting_invite.forward_invite.buttons.done': 'Feito',
  'meeting_invite.forward_invite.buttons.forward': 'Encaminhar convite',
  'meeting_invite.forward_invite.header': 'Encaminhar convite para um colega de equipe',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.label': 'Incluir uma mensagem para os seus colega de equipe:',
  'meeting_invite.forward_invite.inputs.forward_to_colleague.placeholder': 'Eu recebi um convite de reuniāo que é mais relevante para você...',
  'meeting_invite.forward_invite.subheader': 'Encaminhar um convite de reuniāo de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a> para um colega de equipe',
  'meeting_invite.forward_invite.success.message': 'Nós encaminhámos este convite de reuniāo com sucesso para o seu colega de equipe.',
  'meeting_invite.forward_invite.success.title': 'Convite de reuniāo encaminhado para um colega de equipe',
  'meeting_invite.opt_in_meetings.accept': 'Sim, quero participar no serviço de networking',
  'meeting_invite.opt_in_meetings.decline': 'Nāo, nāo quero participar no serviço de networking',
  'meeting_invite.opt_in_meetings.opt_in_meetings_text': 'Participe no serviço de Networking para encontrar contatos, agendar reuniões e receber convites de reuniāo de outros contatos do evento.',
  'meeting_invite.opt_in_meetings.previously_requested_opt_out': 'Você pediu para nāo utilizar o serviço de networking.',
  'meeting_invite.opt_in_meetings.title': 'Participaçāo no serviço de Networking ',
  'meeting_invite.pending_invites.limit_reached.body': 'Você poderá enviar mais convites assim que os seus convites atuais forem aceites.',
  'meeting_invite.pending_invites.limit_reached.title': 'Você atingiu o limite de convintes utilizados',
  'meeting_invite.pending_invites.remaining': '<i class="fa-solid fa-circle-info"></i> Você utilizou <strong>{{ invite_spent }} dos {{ invite_credits }}</strong> convites de reuniāos disponíveis.',
  'meeting_invite.send_invite.buttons.cancel': 'Cancelar',
  'meeting_invite.send_invite.buttons.done': 'Feito',
  'meeting_invite.send_invite.buttons.send': 'Enviar convite',
  'meeting_invite.send_invite.buttons.view_sent_invites': 'Convites enviados',
  'meeting_invite.send_invite.errors.cannot_send_invite': 'Nāo foi possível enviar o convite para este contato.',
  'meeting_invite.send_invite.header': 'Criar um convite de reuniāo',
  'meeting_invite.send_invite.message_placeholder': 'Olá, eu gostaria de me reunir para discutir...',
  'meeting_invite.send_invite.sub_text': 'O seu parceiro de reuniāo irá confirmar a data e hora da sua reuniāo.',
  'meeting_invite.send_invite.success.message': 'O seu parceiro de reuniāo irá rever o seu convite e confirmar a data, hora e localizaçāo baseado na sua disponibilidade.',
  'meeting_invite.send_invite.success.title': 'Convite enviado',
  'meeting_invite.send_invite.success.update_availability': 'Atualizar a minha disponibilidade',
  'meeting_invite.withdraw.buttons.cancel': 'Cancelar',
  'meeting_invite.withdraw.buttons.done': 'Feito',
  'meeting_invite.withdraw.buttons.withdraw': 'Remover convite',
  'meeting_invite.withdraw.errors.cannot_load': 'Convite nāo foi carregado.',
  'meeting_invite.withdraw.header': 'Remover convite',
  'meeting_invite.withdraw.inputs.message.label': 'Adicionar uma mensagem',
  'meeting_invite.withdraw.inputs.reason.label': 'Motivo de cancelamento do convite',
  'meeting_invite.withdraw.inputs.reason.placeholder': 'Selecionar motivo',
  'meeting_invite.withdraw.subheader': 'Remover o convite de reuniāo de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.message': 'Você removeu o convite de reuniāo com sucesso de <strong>{{display_name}}, {{job_title}}</strong> de <a href="{{company_url}}">{{company_display_name}}</a>',
  'meeting_invite.withdraw.success.title': 'Remover convite',
  'meeting_scheduler.acceptInvitation': 'Aceitar convite',
  'meeting_scheduler.buttons.back': 'Voltar',
  'meeting_scheduler.buttons.done': 'Feito',
  'meeting_scheduler.buttons.next': 'Próximo',
  'meeting_scheduler.errors.invite_already_actioned': 'Este convite foi acionado. ',
  'meeting_scheduler.errors.invite_already_actioned_link': '<a href="#/contacts/{{ invite_counterpart_contact_id }}/conversation">View</a>',
  'meeting_scheduler.errors.no_available_slots': 'Nāo há mais espaços de reuniāo disponíveis.',
  'meeting_scheduler.floorplanClickForFull': 'Planta completa do evento',
  'meeting_scheduler.meetingUpdatedTitle': 'Reuniāo atualizada',
  'meeting_scheduler.rescheduleMeeting': 'Reagendar reuniāo',
  'meeting_scheduler.standLocation': '{{ companyName }} estande {{ standNumberFull }}',
  'meeting_scheduler.step.date': 'Data',
  'meeting_scheduler.step.location': 'Local',
  'meeting_scheduler.step.time': 'Hora',
  'meeting_scheduler.unavailable': 'Indisponível',
  'multi_links.add_link': 'Adicionar link',
  'multi_links.address': 'exemplo. https://www.{address}.com/...',
  'multi_links.error': 'Por favor certifique-se que o tipo e o url estāo definidos.',
  'multi_links.please_select': 'Por favor selecione',
  'multi_links.title': 'Links',
  'multi_links.type': 'Type',
  'mux_video_upload.buttons.cancel': 'Cancelar',
  'mux_video_upload.buttons.delete': 'Apagar',
  'mux_video_upload.buttons.rename': 'Renomear',
  'mux_video_upload.buttons.save': 'Salvar',
  'mux_video_upload.buttons.upload': 'Carregar ficheiro',
  'mux_video_upload.error_text': 'Houve um erro ao carregar o seu vídeo, por favor tente de novo',
  'mux_video_upload.input.label': 'Título',
  'mux_video_upload.success_text': 'O seu ficheiro foi carregado com sucesso, estará pronto brevemente',
  'mux_video_upload.video_status.created': 'Novo',
  'mux_video_upload.video_status.deleted': 'Apagado',
  'mux_video_upload.video_status.errored': 'Erro de carregamento',
  'mux_video_upload.video_status.new_video': 'Escolha e carregue o ficheiro',
  'mux_video_upload.video_status.processing': 'Processando',
  'mux_video_upload.video_status.ready': 'Pronto',
  'mux_video_upload.video_status.removed': 'Removido',
  'mux_video_upload.video_status.upload-cancelled': 'Carregamento de ficheiro cancelado',
  'mux_video_upload.video_status.upload-failed': 'O carregamento do ficheiro falhou',
  'mux_video_upload.video_status.upload_failed': 'Carregamento incompleto, por favor apague e tente de novo',
  'mux_video_upload.video_status.uploading': 'Carregando, por favor nāo feche esta página pois pode interromper o seu carregamento',
  'mux_video_upload.video_status.waiting': 'Esperando',
  'my_schedule.bookings.source_types.auto_accepted': 'Aceitados automaticamente',
  'my_schedule.bookings.source_types.ez_match': 'Ez Match',
  'my_schedule.bookings.source_types.concierge_booked': 'Concierge',
  'my_schedule.bookings.source_types.walk_up': 'Agendamentos feitos no local',
  'my_schedule.bookings.table_number': '(Mesa {{ número }})',
  'my_schedule.other_bookings.buttons.add': 'Adicionar',
  'my_schedule.other_bookings.buttons.addOther': 'Outros compromissos',
  'my_schedule.other_bookings.buttons.cancel': 'Cancelar',
  'my_schedule.other_bookings.buttons.delete': 'Apagar',
  'my_schedule.other_bookings.buttons.no_undos': 'Esta açāo nāo poderá ser desfeita.',
  'my_schedule.other_bookings.buttons.update': 'Atualizar',
  'my_schedule.other_bookings.fields.description.label': 'Detalhes',
  'my_schedule.other_bookings.fields.description.placeholder': 'Almoço com o fornecedor',
  'my_schedule.other_bookings.fields.end_datetime.label': 'Fim',
  'my_schedule.other_bookings.fields.start_datetime.label': 'Início',
  'my_schedule.other_bookings.header_add': 'Adicionar',
  'my_schedule.other_bookings.header_update': 'Atualizar',
  'my_schedule.other_bookings.sub_text': "O seu status irá aparecer 'nāo disponível' durante esta hora.",
  'needHelp.body': 'Contate a equipe de concierge do evento.',
  'needHelp.dataPrivacy.body': 'Os leads recolhidos são sujeitos aos termos de uso e políticas de privacidade de dados.',
  'needHelp.dataPrivacy.header': 'Privacidade de dados',
  'needHelp.dataPrivacy.link': 'Política de privacidade',
  'needHelp.header': 'Precisa de ajuda?',
  'needHelp.link': 'Contate-nos',
  'profile.account_settings.email': 'E-mail',
  'profile.account_settings.first_name': 'Primeiro nome',
  'profile.account_settings.last_name': 'Último nome',
  'profile.account_settings.salutation': 'Título',
  'profile.account_settings.title': 'Definições de conta',
  'profile.buttons.cancel': 'Cancelar',
  'profile.buttons.edit': 'Editar',
  'profile.buttons.save': 'Salvar',
  'profile.change_password.change_password': 'Mudar senha',
  'profile.change_password.confirm_password': 'Confirmar senha',
  'profile.change_password.current_password': 'Senha atual',
  'profile.change_password.new_password': 'Nova senha',
  'profile.change_password.title': 'Mudar senha',
  'profile.communication_preferences.email': 'Notificações e lembretes por e-mail',
  'profile.communication_preferences.phone': 'Comunicaçāo e suporte telefônico',
  'profile.communication_preferences.sms': 'Notificações e lembretes por SMS',
  'profile.communication_preferences.something_went_wrong': 'Oops - algo deu errado, por favor tente de novo mais tarde',
  'profile.communication_preferences.subheader': 'Administrar as suas preferências do programa de reuniões',
  'profile.communication_preferences.title': 'Preferências de comunicaçāo',
  'profile.contact_details.add_another_number': 'Adicionar outro número',
  'profile.contact_details.add_number': 'Adicionar número de telefone',
  'profile.contact_details.input_label': '{{ phone_type }} number',
  'profile.contact_details.mobile': 'Número de celular',
  'profile.contact_details.mobile_number': 'Número de celular',
  'profile.contact_details.mobile_placeholder': 'Adicionar número de telefone',
  'profile.contact_details.office': 'Número de escritório',
  'profile.contact_details.office_number': 'Número de escritório',
  'profile.contact_details.phone_number': 'Número de telefone',
  'profile.contact_details.phone_number_missing': 'Você nāo tem números de telefone.',
  'profile.contact_details.phone_placeholder': 'Adicionar número de telefone',
  'profile.contact_details.please_add_a_number': 'Por favor adicionar um número de telefone',
  'profile.contact_details.select_country_code': 'Selecionar código de País',
  'profile.contact_details.title': 'Informaçāo de contato',
  'profile.demographics.subheader': 'Preencha esta informaçāo para ajudar os outros usuários a encontrá-lo na busca',
  'profile.demographics.title': 'Busca de informaçāo',
  'profile.event_features.something_went_wrong': 'Por favor atualize a página e tente de novo',
  'profile.event_features.subheader': 'Recursos específicos do evento.',
  'profile.event_features.title': 'Recursos do evento',
  'profile.event_features.type_state_auto_accept_meeting_invite_false': 'Nāo aceitar automaticamente convites de reuniāo.',
  'profile.event_features.type_state_auto_accept_meeting_invite_true': 'Aceitar automaticamente TODOS os convites de reuniāo.',
  'profile.forms.catalogueEntry.create.modalTitle': 'Adicionar novo {{ name, lowercase }}',
  'profile.forms.catalogueEntry.edit.modalTitle': 'Editar {{ name, lowercase }}',
  'profile.forms.image.create.heading': 'Adicione uma nova imagem para o seu catálogo do produto.',
  'profile.forms.image.create.modalTitle': 'Adicione imagem',
  'profile.forms.image.edit.heading': 'Alterar o título da sua imagem',
  'profile.forms.image.edit.modalTitle': 'Editar imagem',
  'profile.forms.image.progressMessage': 'A sua imagem está carregando, por favor aguarde',
  'profile.forms.video.create.modalTitle': 'Carregue vídeo',
  'profile.forms.video.edit.modalTitle': 'Editar vídeo',
  'profile.forms.video.progressMessage': 'O seu vídeo está carregando, por favor aguarde',
  'profile.groups.aboutMe.heading': 'Sobre mim',
  'profile.groups.accountAccess.heading': 'Acesso de conta',
  'profile.groups.accountAccess.subheading': 'Estes usuários podem gerenciar o seu perfil, chat e reuniões.',
  'profile.groups.catalogue.heading': '{{ plural }}',
  'profile.groups.companyDemographics.heading': 'Outras informações',
  'profile.groups.companyInformation.heading': 'Informações da empresa',
  'profile.groups.companyInformation.subheading': 'Gerencie o seu perfil da empresa',
  'profile.groups.contactInformation.heading': 'Informaçāo de contato',
  'profile.groups.eventOptouts.heading': 'Desativar participaçāo do evento',
  'profile.groups.password.heading': 'Senha da sua conta',
  'profile.groups.password.lastChanged': 'Última alteraçāo em <b> {{ lastChangedAt }} </b>',
  'profile.groups.password.subheading': 'A sua senha deve ser conter pelo menos 8 caracteres e incluir uma letra maíuscula, um número e um caracter especial.',
  'profile.groups.searchInformation.heading': 'Outra informaçāo',
  'profile.groups.team.heading': 'Membros da equipe <small>({{ count }})</small>',
  'profile.groups.timezone.heading': 'Fuso horário',
  'profile.groups.timezone.subheading': 'Visualize os dias e horários do evento no seu fuso horário. <b>(Só eventos virtuais)</b>.',
  'profile.informationSharingNotification': 'A sua informaçāo de contato <b>NĀO</b> será mostrada no seu perfil público.',
  'profile.location_language.country': 'País',
  'profile.location_language.timezone': 'Fuso horário',
  'profile.location_language.title': 'Locallizaçāo e idioma',
  'profile.modals.previewCompanyCard.modalTitle': 'Pré-visualize o seu cartāo da empresa',
  'profile.modals.previewContactCard.': 'Pré-visualizaçāo do seu cartāo',
  'profile.modals.previewContactCard.modalTitle': 'Pré-visualizaçāo do seu cartāo',
  'profile.optouts.something_went_wrong': 'Por favor atualize a página e tente de novo',
  'profile.optouts.subheader': 'Ao selecionar estas opções, você vai esconder o seu perfil e/ou recusar todas as reuniões e outros compromissos.',
  'profile.optouts.title': 'Remover participaçāo',
  'profile.optouts.type_state_findable_false': 'Eu NĀO estou visível no portal.',
  'profile.optouts.type_state_findable_true': 'Eu estou visível no portal.',
  'profile.optouts.type_state_meetable_false': 'Eu NĀO estou disponível para reuniões.',
  'profile.optouts.type_state_meetable_true': 'Eu estou disponível para reuniões.',
  'profile.pages.catalogue.header': 'Marketplace',
  'profile.pages.companyProfile.header': 'Perfil da empresa',
  'profile.pages.companyProfile.previewButton': 'Pré-visualizaçāo do perfil da empresa',
  'profile.pages.manageContent.header': 'Gerenciar conteúdo',
  'profile.pages.manageContent.warning': 'Configurações do evento podem limitar a apresentaçāo de algum conteúdo.',
  'profile.pages.myAccount.action': 'Evento aberto',
  'profile.pages.myAccount.eventAndBadges': 'Os meus eventos & credenciais',
  'profile.pages.myAccount.header': 'Minha Conta',
  'profile.pages.myAccount.welcome': 'Olá {{ firstName }}, bem-vindo (a) à sua conta.',
  'profile.pages.notificationSettings.header': 'Preferências de notificaçāo',
  'profile.pages.passwordSettings.header': 'Senha & configurações',
  'profile.pages.profileInformation.header': 'Meu perfil',
  'profile.pages.profileInformation.previewButton': 'Pré-visualizar o meu perfil',
  'profile.pages.profileInformation.myAccount': 'Minha conta',
  'profile.pages.profileInformation.switchProfile': 'Trocar de perfil',
  'profile.pages.team.header': 'Equipe',
  'profile.pageTitle': 'Minha conta',
  'profile.profile_avatar.recommended_size': 'Recomendado 250px. Tamanho máximo de ficheiro 5Mb.',
  'profile.profile_avatar.title': 'Imagem de perfil',
  'profile.profile_avatar.upload_photo': 'Carregar imagem',
  'profile.profile_information.about_me': 'Sobre mim',
  'profile.profile_information.about_me_placeholder': 'Dê-se a conhecer e explique porque está no evento.',
  'profile.profile_information.job_title': 'Cargo/Funçāo',
  'profile.profile_information.title': 'Informaçāo de perfil',
  'profile.reset_password.sent': 'O e-mail para redefenir a sua senha vai chegar brevemente',
  'profile.reset_password.title': 'Redefenir senha',
  'profile.rows.avatarModalTitle': 'Carregue foto de perfil',
  'profile.rows.bio.bioPlaceholder': 'O meu cargo envolve… ',
  'profile.rows.companyLocations.addModalTitle': 'Adicionar localizaçāo',
  'profile.rows.companyLocations.delete': 'Tem a certeza que deseja apagar esta localizaçāo?',
  'profile.rows.companyLocations.updateModalTitle': 'Editar localizaçāo',
  'profile.rows.companyWebsites.removeWarning': 'Tem a certeza que deseja remover este website o seu perfil?',
  'profile.rows.country.selectCountryPlaceholder': 'Onde está localizado?',
  'profile.rows.fullNameModalTitle': 'Editar nome',
  'profile.rows.logo.logoModalTitle': 'Carregar logotipo da empresa',
  'profile.rows.lookingFor.inputLabel': 'Em que tipo de contatos, empresas, produtos ou serviços você está interessado?',
  'profile.rows.lookingFor.inputPlaceholder': 'Estou buscando ...',
  'profile.rows.lookingFor.label': 'Estou buscando ...',
  'profile.rows.lookingFor.modalTitle': 'Estou buscando ...',
  'profile.rows.password.changeMessage': 'Envie um link de alteraçāo de senha para:',
  'profile.rows.password.label': 'Senha atual',
  'profile.rows.password.modalTitle': 'Alteraçāo de senha',
  'profile.rows.password.passwordLinkSent': 'Link de senha enviado',
  'profile.rows.password.successMessage': '<h4> Email enviado </h4><p> Foi enviado um link para restaurar a sua senha para:<br/><a href="mailto:{{email}}"> {{ email }} </a></p><p> O seu link irá expirar em 1 hora.</p>',
  'profile.rows.phone.deleteWarning': 'Apagar este número de telefone do seu perfil?',
  'profile.rows.phone.info': 'Nós poderemos contatá-lo sobre reuniões ou atualizações importantes sobre o evento através de SMS ou telefone. O seu número não estará visível no seu perfil público.',
  'profile.rows.socials.updateModalTitle': 'Editar redes sociais',
  'profile.rows.websites.addModalTitle': 'Adicionar website',
  'profile.rows.websites.updateModalTitle': 'Editar website',
  'profile.tables.catalogueEntry.confirmDelete': 'Tem a certeza que deseja apagar isto {{ name, lowercase }}?',
  'profile.tables.catalogueEntry.emptyMessage': 'Nāo há artigos para mostrar.',
  'profile.tables.imageGallery.addOne': 'Adicionar',
  'profile.tables.imageGallery.confirmDelete': 'Tem a certeza que deseja apagar esta imagem?',
  'profile.tables.imageGallery.count_one': '({{ count }} artigo)',
  'profile.tables.imageGallery.count_other': '({{ count }} artigos)',
  'profile.tables.imageGallery.emptyMessage': 'Nāo há informações para mostrar.',
  'profile.tables.imageGallery.title': 'Galeria de imagens',
  'profile.tables.videos.addOne': 'Adicionar',
  'profile.tables.videos.confirmDelete': 'Tem a certeza que pretende apagar este vídeo?',
  'profile.tables.videos.count': '({{ count }} / {{ limit }})',
  'profile.tables.videos.emptyMessage': 'Nāo há artigos para mostrar.',
  'profile.tables.videos.title': 'Vídeos de perfil',
  'profile.tips.edit.body': 'A informaçāo usada para verificar a sua identidade e a empresa para que trabalha nāo podem ser modificadas. A informaçāo do contato, preferências e algumas particularidades podem ser editadas, mas podemos ter que pedir para que verifique a sua identidade antes de salvar atualizações.',
  'profile.tips.edit.title': 'Qual a informaçāo que pode ser editada?',
  'profile.tips.preview.title': 'Pré-visualizaçāo do cartāo de perfil',
  'profile.tips.share.body': 'Nós só compartilhamos a sua informaçāo de contato com parceiros de reuniāo confirmados.',
  'profile.tips.share.title': 'Que informaçāo é compartilhada com outros usuários?',
  'profile.action.logout': 'Sair',
  'profile_select.footer.contact_support': 'Contatar equipe de suporte',
  'profile_select.footer.multiple_profiles': 'Porque é que tenho múltiplos perfis?',
  'profile_select.footer.return_to_list': 'Voltar à lista de perfis',
  'profile_select.header.info': 'Clique nas opções do perfil para <b>conetar, combinar or atribuir</b> a um colega de equipe.',
  'profile_select.header.subheader': 'Há mais de um perfil associado com',
  'profile_select.header.title': 'Trocar de perfil',
  'profile_select.info.duplicate_profiles.description': 'Você registrou-se duas vezes.',
  'profile_select.info.duplicate_profiles.solution_text': "Selecione 'combinar perfis' através do menu de opções do perfil e siga os passos para combinar os dados num só perfil. Recomendamos que se conecte e verifique qual o perfil que deseja manter.",
  'profile_select.info.duplicate_profiles.title': 'Existem perfis duplicados no meu nome',
  'profile_select.info.i_see_colleagues.description': 'A sua empresa pode ter registrado vários colegas usando o mesmo endereço de e-mail , por exemplo: info@.',
  'profile_select.info.i_see_colleagues.solution_text': 'Selecionar conexāo ao colega através do menu de opções do perfil.',
  'profile_select.info.i_see_colleagues.title': 'Eu consigo ver os nomes de outros colegas da minha empresa.',
  'profile_select.info.listed_different_company.description': 'Você pode estar registrado ou estar representando empresas diferentes. Se isto estiver correto, você pode manter e trocar os múltiplos perfis, utilizando a página de selecionar perfil para mudar entre perfis. Se isto estiver incorreto:',
  'profile_select.info.listed_different_company.solution_text': "Selecione 'apagar'através do menu de opções do perfil. Este processo nāo pode ser anulado, recomendamos que se conecte e confira os seus perfis antes de os apagar.",
  'profile_select.info.listed_different_company.title': 'Estou listado com diferentes nomes de empresa.',
  'profile_select.info.solution': 'Soluçāo:',
  'profile_select.info.title': 'Porque é que eu tenho perfis múltiplos?',
  'profile_select.profile.last_used': 'Utilizado recentemente:',
  'profile_select.profile.never_logged_in': 'Nunca',
  'profile_select.primary_contact': 'Contato primário',
  'ui.add': 'Adicionar',
  'ui.delete': 'Apagar',
  'ui.edit': 'Editar',
  'ui.faq': 'Perguntas frequentes',
  'ui.help': 'Ajuda',
  'ui.language': 'Idioma',
  'ui.locked': 'Bloqueado',
  'ui.new': 'Novo',
  'ui.noItemsToDisplay': 'Nāo há artigos para mostrar.',
  'ui.notAdded': 'Nāo adicionado',
  'ui.notSet': 'Nāo configurado',
  'ui.selectCountry': 'Selecionar País',
  'ui.support': 'Assistência',
  'ui.switchProfile': 'Mudar de perfil',
  'update_availability.buttons.cancel': 'Cancelar',
  'update_availability.buttons.update': 'Atualize disponibilidade',
  'update_availability.header': 'A sua disponibilidade',
  'update_availability.key.available': 'Disponível',
  'update_availability.key.unavailable': 'Indisponível',
  'update_availability.subheader': 'Definir quais os dias e horas em que vai participar no evento.',
  'video_meeting.message_concierge.box_closing': 'Esta caixa irá fechar automaticamente em 5 segundos.',
  'video_meeting.message_concierge.buttons.cancel': 'Cancelar',
  'video_meeting.message_concierge.buttons.message_concierge': 'Envie mensagem ao concierge',
  'video_meeting.message_concierge.buttons.send': 'Enviar',
  'video_meeting.message_concierge.connecting': 'conetando',
  'video_meeting.message_concierge.header': 'Pedir ajuda ao concierge',
  'video_meeting.message_concierge.last_seen': '(Ativo hà {{ last_seen }})',
  'video_meeting.message_concierge.message_sent': 'A sua mensagem foi enviada para a equipe de concierge, visualize a página de chat para obter uma resposta.',
  'video_meeting.message_concierge.online': 'online',
  'video_meeting.message_concierge.waiting_join': 'Aguardando usuário',

  'hubs.header.stand': 'Estande: {{ stand }}',
  'hubs.companyProfile': 'Perfil da empresa',
  'hubs.definitions.confirmedMeetings': 'Reuniões confirmadas',
  'hubs.definitions.receivedPendingInvites': 'Convites de reunião em aberto',
  'hubs.definitions.sentPendingInvites': 'Pedidos de reunião em aberto',
  'hubs.definitions.sentInvites': 'Convites de reunião enviados (all statuses)',

  // Hubs Event Profile
  'hubs.eventProfile.activity.favourited': 'Favoritos',
  'hubs.eventProfile.activity.hides': 'Esconder',
  'hubs.eventProfile.activity.marketplacePerformance': 'Performance',
  'hubs.eventProfile.activity.marketplacePerformanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.activity.marketplaceViews': 'Visualizações',
  'hubs.eventProfile.activity.noData': 'Não há dados suficiente',
  'hubs.eventProfile.activity.performance': 'Desempenho do perfil',
  'hubs.eventProfile.activity.profileViews': 'Visualizacões de perfil',
  'hubs.eventProfile.catalogueActive': 'Acessos ativos',
  'hubs.eventProfile.catalogueDescription': 'Use os diretórios do catálogo para promover os seus produtos e serviços',
  'hubs.eventProfile.catalogueTitle': 'Catálogo',
  'hubs.eventProfile.countPlays': '{{ count }} visualizações',
  'hubs.eventProfile.description': 'Gerencie seu perfil e promova sua presença no evento.',
  'hubs.eventProfile.emptyList': 'Nenhum item listado',
  'hubs.eventProfile.link': 'Perfil do evento',
  'hubs.eventProfile.marketplaceActive': 'Ativo',
  'hubs.eventProfile.marketplaceDescription': 'Promova produtos e serviços através do diretório do Marketplace.',
  'hubs.eventProfile.marketplaceTitle': 'Marketplace',
  'hubs.eventProfile.performanceScore': 'Top {{ score }}%',
  'hubs.eventProfile.title': 'Perfil do evento',
  'hubs.eventProfile.videos': 'Vídeos',
  'hubs.eventProfile.videosActive': 'Vídeos ativos',
  'hubs.eventProfile.videosDescription': 'Gerencie seus vídeos promocionais.',
  'hubs.eventProfile.videosTitle': 'Vídeos',
  'hubs.eventProfile.viewsPerDay': 'Visualizações diárias do perfil',

  // hubs insights
  'hubs.insights.activity': 'Desempenho do perfil da empresa',
  'hubs.insights.activity.favourited': 'Adicionado aos favoritos',
  'hubs.insights.activity.hides': 'Adicionado às peles',
  'hubs.insights.activity.profilePreviews': 'Pré-visualizações de perfil',
  'hubs.insights.activity.profileViews': 'Visualizações da página de perfil',
  'hubs.insights.activity.noData': 'Dados insuficientes para mostrar.',
  'hubs.insights.description': 'Revise o desempenho do seu evento.',
  'hubs.insights.meetings': 'Desempenho das reuniões da empresa',
  'hubs.insights.link': 'Perspectivas',
  'hubs.insights.title': 'Perspectivas',

  // hubs leads
  'hubs.leads.businessCardDropTable': 'Cartões de visita recebidos',
  'hubs.leads.businessCardDropTableDescription': 'Os seguintes usuários deixaram seu cartão de visita disponível para visualização no perfil da sua empresa',
  'hubs.leads.description': 'Acessar os seus leads e cartões de visita recebidos.',
  'hubs.leads.download': 'Baixar leads',
  'hubs.leads.downloadFormat': 'CSV / Excel',
  'hubs.leads.link': 'Leads',
  'hubs.leads.meetingsWith': 'Reunião com {{ people }}',
  'hubs.leads.noDroppedBusinessCards': 'Nenhum contato deixou cartão de visita ainda.',
  'hubs.leads.numberOfBusinessCardDrops': 'Cartões de visita',
  'hubs.leads.receivedInvitesFrom': 'Pedidos de reunião recebidos de {{ people }}',
  'hubs.leads.sentInvitesTo': 'Pedidos de reunião enviados para {{ people }}',
  'hubs.leads.title': 'Gerenciamento de Lead ',

  // Hubs Teams
  'hubs.team.activityChartSubtitle': 'últimos 60 dias',
  'hubs.team.activityChartTitle': 'atividade da equipe - ',
  'hubs.team.badgeTable': 'Equipe',
  'hubs.team.contactUpdatedPlatformProfile': 'Perfis atualizados',
  'hubs.team.description': 'Monitore a atividade e a presença de sua equipe.',
  'hubs.team.lastLogin': 'online pela última vez',
  'hubs.team.link': 'Equipe',
  'hubs.team.noOtherBadges': 'Nenhum colega está registrado para o evento.',
  'hubs.team.profilesActivated': 'Perfis atualizados',
  'hubs.team.profilesHidden': 'Perfis ocultos',
  'hubs.team.title': 'Equipe',
  'hubs.team.register_colleague': 'Registrar colega',
  'hubs.team.register': 'Registrar',
  'hubs.team.country': 'País',
  'hubs.team.email': 'E-mail',
  'hubs.team.firstName': 'Nome',
  'hubs.team.jobTitle': 'Cargo',
  'hubs.team.lastName': 'Sobrenome',

  // Hubs Meetings
  'hubs.meetings.confirmedMeetings': 'Reuniões confirmadas',
  'hubs.meetings.description': 'Revise a atividade de reunião pré-agendada por toda sua empresa.',
  'hubs.meetings.link': 'Reuniões',
  'hubs.meetings.MeetingsByDayTitle': 'Agenda de reunião diária',
  'hubs.meetings.MeetingsByLocationTitle': 'Agenda de reunião estabelecida por localização ',
  'hubs.meetings.performance': 'Perfomance da reunião',
  'hubs.meetings.performanceScore': 'Top {{score}}%',
  'hubs.meetings.receivedInvites': 'Convites Pendentes',
  'hubs.meetings.sentInvites': 'Pedidos enviados',
  'hubs.meetings.teamHasMeetings': 'Reuniões confirmadas',
  'hubs.meetings.teamHasPending': 'Reuniões pendentes',
  'hubs.meetings.teamHasSent': 'Pedidos de reunião enviados',
  'hubs.meetings.teamTitle': 'Reuniões da equipe',
  'hubs.meetings.title': 'Reuniões',

  'hubs.stats.average': 'Desempenho médio',
  'hubs.stats.belowAverage': 'Desempenho abaixo da média',

  // hubs meetings team schedules
  'hubs.meetings.teamSchedules.unavailable': 'Reservado como Indisponível',
  'hubs.meetings.teamSchedules': 'Horários combinados da equipe',
  'hubs.meetings.teamSchedules.instructions': 'Selecione um dia para ver a agenda de um contato em um período específico.',
  'hubs.meetings.teamSchedules.bookings.empty': '{{sharer}} não tem reservas durante este período.',
  'hubs.meetings.teamSchedules.combinedBookings.empty': 'Nenhuma reserva durante este período.',

  'meeting_invite.notification.confirmed_meeting': 'Você tem uma <a href="{{hash}}">reunião confirmada</a> com este contato.',
  'meeting_invite.notification.invite_already_sent': '<a href="{{hash}}">Convite já enviado</a> para este contato.',
  'meeting_invite.notification.invite_already_received': 'Você tem um <a href="{{hash}}">novo convite para reunião</a> deste contato.',
  'meeting_invite.notification.invite_withdrew_by_ac': 'Convite retirado em {{withdrawn_time}}. <a href="{{hash}}">Ver detalhes</a>.',
  'meeting_invite.notification.meeting_cancelled_by': 'Uma reunião anterior foi cancelada.<a href="{{hash}}">Ver reunião</a>.',
  'meeting_invite.notification.meeting_with_contact_colleague': 'Você tem uma <a href="{{hash}}">reunião confirmada</a> com esta empresa.',
  'meeting_invite.notification.meeting_with_contact_declined': 'Seu <a href="{{hash}}">convite para a reunião</a> foi recusado.',
  'meeting_invite.notification.meeting_with_contact_declined_by_you': 'Você recusou um <a href="{{hash}}">convite para reunião</a> deste contato.',
  'meeting_invite.notification.colleague_meeting_intersect': 'Seu colega está se reunindo com esta empresa.',
  'meeting_invite.notification.meeting_with_colleague_confirmed': 'Seu colega está se reunindo com este contato.',
  'meeting.notification.view_meeting': 'Ver reunião',

  // purchase items
  'hubs.purchase_items.available': 'Disponível',
  'hubs.purchase_items.emptyList': 'Item de compra não disponível.',
  'hubs.purchase_items.link': 'Atualizações e Ofertas',
  'hubs.purchase_items.offers': 'Ofertas',
  'hubs.purchase_items.out_of_stock': 'Fora de estoque',
  'hubs.purchase_items.product_not_available': 'Produto indisponível',
  'hubs.purchase_items.purchased': 'Adquirido',
  'hubs.purchase_items.purchases': 'Compras',
  'hubs.purchase_items.someAvailable': '{{ num }} disponíveis',
  'hubs.purchases.purchased_at': 'Adquirido em: ',
  'hubs.purchases.purchased_by': 'Adquirido por: {{ contact }}',

  // Registrations
  'registrations.form.questions.component.name.first_name': 'Primeiro nome',
  'registrations.form.questions.component.name.last_name': 'Sobrenome',
  'registrations.form.questions.component.name.first_name_placeholder': 'Introduza o primeiro nome',
  'registrations.form.questions.component.name.last_name_placeholder': 'Insira o último nome',
  'registrations.form.actions.submit': 'Enviar',
  'registrations.form.actions.next': 'Próxima',
  'registrations.form.screens.thankyou.heading': '<strong>Congratulations {{ name }}</strong>, your {{ eventName }} registration is complete.',
  'registrations.form.screens.thankyou.sub_heading': 'You will receive a confirmation email shortly.',
  'registrations.form.screens.not_active.description': 'Este formulário de registro não está disponível no momento. Tente novamente mais tarde.',
  'registrations.form.screens.closed.alert_message': 'As inscrições foram encerradas.',
  'registrations.form.screens.coming_soon.alert_message': 'As inscrições abrem em breve.',
  'registrations.form.actions.send_login_link': 'Envoyer un lien de connexion',
  'registrations.form.actions.change_email_address': "Changer l'adresse email",
  'registrations.form.actions.send_link_to_continue': 'Envoyer un lien pour continuer',
  'registrations.form.validation.email_already_registered_message': 'Votre adresse email est déjà enregistrée...',
  'registrations.form.validation.email_already_have_partial_registration_message': 'Votre adresse email a une inscription partielle...',
  'registrations.form.buttons.login': 'Log in',
  'registrations.form.actions.resend_email': 'Resend email',
  'registrations.form.actions.resend_email.message': 'Email sent!',

  // Videos
  'video_card.more': 'Mais detalhes',
  'video_card.view_detail': 'Detalhes do vídeo',
  'videoModal.buttons.view_company': 'Ver empresa',

  // Tasks
  'hubs.tasks.link': 'Tarefas',
  'hubs.tasks.title': 'Tarefas',
  'hubs.tasks.taskProgress': 'Progresso da tarefa',
  'hubs.tasks.pendingTasksTitle': 'Tarefas pendentes',
  'hubs.tasks.pendingCompanyTasksDescription': 'As seguintes tarefas precisam ser completadas por um representante da sua empresa.',
  'hubs.tasks.pendingContactTasksDescription': 'As seguintes tarefas precisam ser completadas por você.',
  'hubs.tasks.noPendingTasks': 'Não há tarefas a serem concluídas.',
  'hubs.tasks.taskType.mandatory': 'Obrigatório',
  'hubs.tasks.taskType.important': 'Importante',
  'hubs.tasks.taskType.optional': 'Opcional',
  'hubs.tasks.completedTasksTitle': 'Tarefas concluídas',
  'hubs.tasks.completedTasksDescription': 'As seguintes tarefas foram concluídas.',
  'hubs.tasks.nocompletedTasks': 'Você não tem tarefas concluídas.',
  'hubs.tasks.start': 'Iniciar',
  'hubs.tasks.expired': 'Expirado',
  'hubs.tasks.view': 'Ver',
  'hubs.tasks.modal.title': 'Detalhes da tarefa',
  'hubs.tasks.modal.cancel': 'Cancelar',
  'hubs.tasks.modal.markAsRead': 'Marcar como lida',
  'hubs.tasks.modal.submit': 'Enviar',
  'hubs.tasks.modal.close': 'Fechar',
  'hubs.tasks.completed_by': 'Concluído por {{ responder }} em {{ when }}',
  'hubs.tasks.file_submitted': 'Você já enviou um arquivo',
  'hubs.tasks.file_download': 'Baixar arquivo',
  'hubs.tasks.user_file_download': 'Baixar arquivo',
  'hubs.tasks.capacity_remaining': '{{ capacity_remaining }} restantes',
  'hubs.tasks.capacity_remaining_zero': 'Fora de estoque',
  'hubs.tasks.deadline': 'Prazo',
  'hubs.tasks.completed': 'Concluído',

  'floorplan.open_location_on_map': 'Abrir mapa',
};
