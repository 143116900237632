<template>
  <div ref="directory" class="directory">

    <div class="directory__controls" v-if="!hideFilters">

    <button v-if="canExportWishlist && !mobile"
      class="button button--primary button--compact"
      type="button"
      :data-tooltip="__('directory.export_wishlist')"
      @click="exportWishlist"
    >
      <i class="fa-solid fa-cloud-arrow-down"></i>
    </button>

    <!-- Primary filters -->
    <template
      v-for="filter in primaryFilters"
      :key="filter.name"
      >
      <DirectoryFilter
        :icon="'far ' + filter.icon"
        :label="selected[filter.name]?.length > 0 ? optionLabelsFlat[filter.name].find((item) => item.value === selected[filter.name][0])?.label : filter.label"
        :count="selected[filter.name]?.length - 1"
        @click="openFilterModals[filter.name] = true"
      />

      <FiltersModal
        v-if="openFilterModals[filter.name]"
        :icon="'far ' + filter.icon"
        :title="__('directory.filters.filters')"
        :filters="[filter]"
        :initialSelected="selected"
        :initialSort="sort"
        @updated="(newSelected, newSort) => this.updateSelectedAndCloseModal(filter.name, newSelected, newSort)"
        @closed="openFilterModals[filter.name] = false"
      />
    </template>

  <!-- Separator -->
  <template v-if="!mobile && primaryFilters.length > 0 && secondaryFilters.length > 0">
    <i class="far fa-shuffle" style="font-size: var(--font-large);"></i>
  </template>

  <!-- Secondary -->
  <template v-if="!mobile">
    <template
    v-for="filter in secondaryFilters"
      :key="filter.name"
    >
    <DirectoryFilter
      :icon="'far ' + filter.icon"
      :label="selected[filter.name]?.length > 0 ? optionLabelsFlat[filter.name].find((item) => item.value === selected[filter.name][0])?.label : filter.label"
      :count="selected[filter.name]?.length - 1"
      @click="openFilterModals[filter.name] = true"
    />

    <FiltersModal
      v-if="openFilterModals[filter.name]"
      :icon="'far ' + filter.icon"
      :title="filter.label"
      :filters="[filter]"
      :initialSelected="selected"
      :initialSort="sort"
      hideLabels
      @updated="(newSelected, newSort) => this.updateSelectedAndCloseModal(filter.name, newSelected, newSort)"
      @closed="openFilterModals[filter.name] = false"
    />
  </template>
</template>

<!-- Tertiary/Modal filters -->
<template v-if="!mobile && tertiaryFilters.length > 0">
  <DirectoryFilter
    :label="__('directory.filters.open')"
    icon="far fa-sliders-up"
    :count="tertiaryFiltersSelectedCount"
    @click="openFilterModals['tertiary'] = true"
  />

  <FiltersModal
    v-if="openFilterModals['tertiary']"
    :title="__('directory.filters.filters')"
    icon="far fa-sliders-up"
    :filters="tertiaryFilters"
    :initialSelected="selected"
    :sorts="sorts"
    :initialSort="sort"
    @updated="(newSelected, newSort) => this.updateSelectedAndCloseModal('tertiary', newSelected, newSort)"
    @closed="openFilterModals['tertiary'] = false"
  />
</template>

  <SaveSearchModal
    :title="__('directory.save_search')"
    :notificationFrequencies="notificationFrequencies"
    :selectedCriteria="selected"
    :directoryId="directoryId"
    v-model:visible="saveSearchOpen"
    v-if="savedSearchFeature"
  >
  </SaveSearchModal>

  <template v-if="mobile && nonPrimaryFilters.length > 0">
    <DirectoryFilter
      :label="__('directory.filters.open')"
      icon="far fa-sliders-up"
      :count="nonPrimaryFiltersSelectedCount"
      @click="openFilterModals['mobile'] = true"
    />
    <FiltersModal
      v-if="openFilterModals['mobile']"
      :title="__('directory.filters.filters')"
      icon="far fa-sliders-up"
      :filters="nonPrimaryFilters"
      :initialSelected="selected"
      :sorts="sorts"
      :initialSort="sort"
      @updated="(newSelected, newSort) => this.updateSelectedAndCloseModal('mobile', newSelected, newSort)"
      @closed="openFilterModals['mobile'] = false"
    />
  </template>
  <div v-if="savedSearchFeature">
      <button
        @click="openSaveSearchModal"
        v-if="visibleSelectedCount"
      >
        <i class="far fa-bell"></i>
        <span class="text-primary" style="margin-left: var(--sizing-2);">{{ __('directory.save_search') }}</span>
      </button>
  </div>
</div>
  <div class="directory__body" v-show="showResults">
    <div
    v-if="results.length"
    :class="{
      'directory__results--table': layout === 'table',
      'ee_directory__container': layout === 'four-grid',
    }"
    class="directory__results"
    >
    <TransitionGroup name="t-chat">
      <component
        v-for="data in paginator.data"
        :is="data.component"
        :key="data.id"
        :model="data"
        :showMatchButtons="showMatchButtons"
      />
    </TransitionGroup>
    <div class="directory__actions">
      <div class="button-group">
        <LoadMoreButton
          v-if="!isLastPage"
          :loading="loading"
          @loadMore="loadMore"
        />
      </div>
    </div>
  </div>
  <FeLoading v-else-if="loading" />
    <div v-else class="directory__results">
      <div class="directory__no-results">
        <h2>
          {{ __(noResults) }}
        </h2>
        <p>
          {{ __(noResultsMessage) }}
        </p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';
import { nextTick } from 'vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import LoadMoreButton from '~/Components/Frontend/LoadMoreButton.vue';
import { useBreakpoints } from '~/Frontend/useBreakpoints';
import DirectoryFilter from './DirectoryFilter.vue';
import FiltersModal from './FiltersModal.vue';
import SaveSearchModal from './SaveSearchModal.vue';

export default {
  components: {
    DirectoryFilter,
    FiltersModal,
    LoadMoreButton,
    FeLoading,
    SaveSearchModal,
  },
  props: {
    // only used for contact directories
    showMatchButtons: Boolean,
    filters: {
      type: Array,
      default: () => [],
    },
    sorts: Object,
    layout: {
      default: 'grid',
      validator: (val) => ['grid', 'table', 'four-grid'].includes(val),
      type: String,
    },
    criteria: {
      type: Array,
      default: () => [],
    },
    enableTextSearch: Boolean,
    textSearchInput: String,
    canExportWishlist: {
      type: Boolean,
      default: false,
    },
    hideIfPrimaryBlank: {
      type: Boolean,
      default: false,
    },
    initialSelected: {
      type: Object,
      default: () => ({}),
    },
    initialSort: {
      type: String,
      required: false,
    },
    noResults: {
      type: String,
      default: 'directory.noResults.title',
    },
    noResultsMessage: {
      type: String,
      default: 'directory.noResults.message',
    },
    hideFilters: {
      type: Boolean,
    },
    notificationFrequencies: {
      type: Array,
      default: () => ([]),
    },
    directoryId: {
      type: Number,
    },
    savedSearchFeature: {
      type: Boolean,
    },
  },
  setup() {
    const breakpoints = useBreakpoints();
    const mobile = breakpoints.smaller('tablet');
    return { mobile };
  },
  data() {
    const data = {
      debounceTimer: false,
      filtersClosed: false,
      loading: false,
      selected: {},
      textSearch: this.textSearchInput,
      createdSince: new URL(window.location.href).searchParams.get('created_at') || 0,
      paginator: null,
      openFilterModals: [],
      optionLabelsFlat: {},
      sort: [this.initialSort], // array so the multileveldropdown likes it
      savedSearchName: null,
      savedSearchNotificationFrequency: null,
      saveSearchOpen: false,
      isSearchRequest: false,
    };

    Object.keys(this.initialSelected).forEach((key) => {
      if (this.filters.find((filter) => filter.name === key)) {
        data.selected[key] = this.initialSelected[key];
      } else if (this.criteria.find((criteria) => criteria.name === key)) {
        data.selected[key] = this.initialSelected[key];
      }
    });

    this.filters.filter((filter) => !(filter.name in data.selected))
      .forEach((filter) => {
        if (filter.input_type === 'DirectoryRadioFilter') {
          data.selected[filter.name] = false;
        } else {
          data.selected[filter.name] = [];
        }
      });

    this.filters.forEach((filter) => {
      data.optionLabelsFlat[filter.name] = this.flattenTree(filter.options);
    });

    return data;
  },
  emits: ['update:paginator'],
  computed: {
    isFirstPage() {
      return this.page === 1;
    },
    isLastPage() {
      return this.paginator && (this.paginator.meta?.next_page_url === undefined || this.paginator.meta?.next_page_url === null);
    },
    page() {
      return this.paginator && this.paginator.meta?.current_page;
    },
    results() {
      return this.paginator ? this.paginator.data : [];
    },
    visibleFilters() {
      return this.filters
        .filter((filter) => !filter.hidden)
        .filter((filter) => (filter.input_type === 'DirectoryTextSearchFilter' || filter.input_type === 'DirectoryRadioFilter') || filter.options.length > 0)
        .filter((filter) => (
          (!filter.dependent_on)
      || (filter.dependent_on.length === 0)
      || filter.dependent_on.some(
        (f) => (!(f.filter_name in this.selected))
      || this.selected[f.filter_name].includes(f.value),
      )
        ));
    },
    visibleSelectedCount() {
      return this.visibleFilters.reduce((accumulator, filter) => {
        if (Array.isArray(this.selected[filter.name])) {
          return accumulator + this.selected[filter.name].length;
        }

        return accumulator + (this.selected[filter.name] ? 1 : 0);
      }, 0);
    },
    hiddenFilters() {
      return this.filters.filter(
        (filter) => !this.visibleFilters.find((f) => filter.name === f.name),
      );
    },
    primaryFilters() {
      return this.visibleFilters.filter((filter) => filter.priority === 'primary');
    },
    secondaryFilters() {
      return this.visibleFilters.filter((filter) => filter.priority === 'secondary');
    },
    tertiaryFilters() {
      return this.visibleFilters.filter((filter) => filter.priority === 'tertiary');
    },
    tertiaryFiltersSelectedCount() {
      return this.tertiaryFilters.reduce((accumulator, filter) => {
        if (Array.isArray(this.selected[filter.name])) {
          return accumulator + this.selected[filter.name].length;
        }

        return accumulator + (this.selected[filter.name] ? 1 : 0);
      }, 0);
    },
    nonPrimaryFilters() {
      return this.visibleFilters.filter((filter) => filter.priority !== 'primary');
    },
    nonPrimaryFiltersSelectedCount() {
      return this.nonPrimaryFilters.reduce((accumulator, filter) => {
        if (Array.isArray(this.selected[filter.name])) {
          return accumulator + this.selected[filter.name].length;
        }

        return accumulator + (this.selected[filter.name] ? 1 : 0);
      }, 0);
    },
    showResults() {
      if (!this.hideIfPrimaryBlank) {
        return true;
      }
      if (this.primaryFilters.length === 0) {
        return true;
      }
      return this.selected[this.primaryFilters[0].name].length > 0;
    },
  },
  methods: {
    getQueryParams() {
      return qs.parse(window.location.search.substring(1));
    },
    updateQueryParams(params) {
      const path = window.location.href.split('?')[0];
      return `${path}/search?${qs.stringify(params)}`;
    },
    /**
    * Remove any of our params for the query string
    * */
    cleanQueryParams() {
      const params = this.getQueryParams();
      const staleParamNames = Object.keys(this.getParams());
      staleParamNames.forEach((name) => delete params[name]);
    },
    getParams() {
      const params = this.selected;

      if (this.enableTextSearch) {
        params.text_search = this.textSearch;
      }

      [params.sort] = this.sort;

      this.criteria.forEach((filter) => {
        params[filter.name] ??= [];
        if (Array.isArray(filter.value)) {
          params[filter.name] = [...params[filter.name], ...filter.value];
        } else {
          params[filter.name] = filter.value;
        }
      });

      return Object.keys(params).reduce((acc, key) => {
        if (!_.isBoolean(params[key]) || params[key]) { // Skip boolean false value in get request
          acc[key] = params[key];
        }
        return acc;
      }, {});
    },
    flattenTree(options) {
      let flatOptions = [];

      if (!options) {
        return [];
      }
      options.forEach((option) => {
        if (option.options?.length > 0) {
          flatOptions = [
            ...flatOptions,
            ...this.flattenTree(option.options),
          ];
        } else {
          flatOptions.push({
            value: option.value,
            label: option.label,
          });
        }
      });
      return flatOptions;
    },
    loadMore() {
      if (this.isLastPage || this.loading) {
        return;
      }
      const scrollToTop = false;
      this.load(this.page + 1, false, scrollToTop);
    },
    updateSelectedAndCloseModal(modalKey, newSelected, sort) {
      this.openFilterModals[modalKey] = false;

      Object.assign(this.selected, newSelected);
      this.hiddenFilters.forEach((filter) => {
        this.selected[filter.name] = [];
      });
      this.sort = [...sort];
      this.load(1);
    },
    debounceIndex(page) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.load(page);
      }, 800);
    },
    load(page, exportCsv, scrollToTop) {
      this.loading = true;
      const params = {
        ...this.getQueryParams(),
        ...this.getParams(),
      };

      if (page) {
        params.page = page;
      }

      const url = this.updateQueryParams(params);

      if (this.canExportWishlist && exportCsv) {
        this.loading = false;
        axios.post(`${url}&export-wishlist=1`, null, { responseType: 'blob' })
          .then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const fileName = response.headers['content-disposition'].match(/filename=([^\s]+)/)[1];
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          });
        this.loading = false;
        return;
      }

      // First page load send isSearchRequest = false
      // on subsequent requests this is true so we log a search
      axios.post(`${url}&ajax=true`, { is_search_request: this.isSearchRequest }).then((response) => {
        if (response.data.meta.current_page === 1) {
          this.replaceResults(response.data);
        } else {
          this.patchResults(response.data);
        }
        this.isSearchRequest = true;
      }).then(() => {
        if (scrollToTop) {
          this.$refs.directory.scrollIntoView();
        }
        this.loading = false;
      });
    },
    patchResults(newPaginator) {
      const paginator = this.addDirectorySpot(newPaginator, newPaginator.meta.spot);
      this.paginator.meta.current_page = newPaginator.meta?.current_page;
      this.paginator.meta.next_page_url = newPaginator.meta?.next_page_url;
      this.paginator.meta.prev_page_url = newPaginator.meta?.prev_page_url;
      this.paginator.data.push(...paginator.data);
    },
    replaceResults(newPaginator) {
      this.paginator = this.addDirectorySpot(newPaginator, newPaginator.meta.spot);
    },
    exportWishlist() {
      this.load(1, true);
    },
    openSaveSearchModal() {
      this.saveSearchOpen = true;
    },
    addDirectorySpot(newPaginator, spot) {
      if (!spot) {
        return newPaginator;
      }

      // new implementation
      if (spot.enable_iab_format) {
        newPaginator.data.splice(0, 0, spot);
        return newPaginator;
      }

      // old implementation
      newPaginator.data.splice(6, 0, spot);
      return newPaginator;
    },
  },
  mounted() {
    nextTick(
      () => {
        this.load(1);
        if (this.primaryFilters.length === 1 && this.primaryFilters[0].options.length === 1) {
          this.selected[this.primaryFilters[0].name] = [this.primaryFilters[0].options[0].value];
        }
        this.emitter.on('update-match-made-contacts', (data) => {
          this.paginator.data = this.paginator.data.map((item) => ({
            ...item,
            match: {
              ...item.match,
              under_matchmade_limit: data.under_matchmade_limit,
            },
          }));
        });
      },
    );
  },
  beforeUnmount() {
    this.cleanQueryParams();
  },
};
</script>
