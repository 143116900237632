import { ref, onBeforeUnmount } from 'vue';

export default function useCurrentTime() {
  const currentTime = ref(new Date());

  // Function to update the current time
  const updateCurrentTime = () => {
    currentTime.value = new Date();
  };

  // Set an interval to update the current time every second
  const timeInterval = setInterval(updateCurrentTime, 1000);

  // Cleanup the interval when the component is about to unmount
  onBeforeUnmount(() => {
    clearInterval(timeInterval);
  });

  return { currentTime };
}
