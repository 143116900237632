<template>
  <div class="floorplan-page__search-input">
    <FrontendTextInput
      ref="searchInputElement"
      :id="searchInputId"
      :key="searchInputId"
      :name="searchInputId"
      v-model="model"
      :max="500"
      hideCounter
      placeholderIcon="far fa-search"
      :placeholder="searchInputPlaceholder"
      @focus="$emit('searchFocussed')"
      :autofocus="autofocus"
    />

    <CloseButton
      v-if="showCloseSearchButton"
      buttonClass="floorplan-page__search-close"
      @click="$emit('close')"
    />
  </div>
  <div class="floorplan-page__locations">
    <ul class="floorplan-page__location-list">
      <li
        v-for="location in filteredLocations"
        :key="location.id"
        :id="`locationList_${location.id}`"
        class="floorplan-page__location-list-item"
        :class="{
          'floorplan-page__location-list-item--active': location.id.toString() === selectedLocationId.toString(),
        }"
        @click="$emit('locationClicked', location)"
      >
        <span
          v-if="location.name !== 'Available' && location.name !== 'Reserved'"
          class="floorplan-page__location-name"
        >
          {{ location.name }}
        </span>
        <span
          v-if="location.number"
          class="floorplan-page__location-number"
        >
          {{ location.number }}
        </span>

        <div
          v-show="location.id.toString() === selectedLocationId.toString()"
          class="floorplan-page__location-buttons"
        >
          <a
            v-if="showNavigationButton"
            @click.prevent.stop="$emit('navigationClicked', location)"
            class="floorplan-page__location-button"
          >
            <i class="far fa-route"></i>
          </a>
          <a
            v-if="showPreviewButton && location.routes.preview"
            :href="location.routes.preview"
            @click.stop
            class="floorplan-page__location-button"
          >
            <i class="far fa-info-circle"></i>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { ref, computed } from 'vue';
import CloseButton from '~/Components/Frontend/CloseButton.vue';

const props = defineProps({
  searchInputId: String,
  searchInputPlaceholder: String,
  locations: Array,
  selectedLocationId: [String, Number],
  showCloseSearchButton: Boolean,
  showNavigationButton: Boolean,
  showPreviewButton: Boolean,
  autofocus: Boolean,
});

const model = defineModel();

defineEmits([
  'searchFocussed',
  'locationClicked',
  'navigationClicked',
  'close',
]);

const searchInputElement = ref(null);

const filteredLocations = computed(() => {
  if (!props.locations) {
    return [];
  }

  if (model.value.length > 1) {
    const search = model.value.trim().toLowerCase();
    return _.sortBy(props.locations.filter((item) => item.name.toLowerCase().includes(search) || item.number.toLowerCase().includes(search)), ['name', 'number']);
  }
  return _.sortBy(props.locations, ['name', 'number']);
});

</script>
