<template>
  <div class="match-list__row">
    <div class="match-list__row-avatar">
      <ContactAvatar :contact="contact" size="medium" />
    </div>
    <div class="match-list__row-text">
      <div class="match-list__row-text-1">
        <strong>{{ contact.display_name }}</strong>, {{ contact.job_title }}
      </div>
      <div class="match-list__row-text-2">
        {{ contact.company.display_name }}
        <div
          v-for="badgeLabel in contact.badge_types"
          :key="badgeLabel"
          class="chip chip--small"
        >
          {{ badgeLabel }}
        </div>
      </div>
    </div>
    <SubOptions>
      <template v-slot:button="{toggle}">
        <IconButton @click.stop="toggle" small tinted icon="fa-ellipsis" />
      </template>
      <SubOption
        :link="`#/contacts/${contact.id}/profile`"
        :label="__('directory.match_made.view_profile')"
      />
      <SubOption
        v-if="matched"
        @clicked="$emit('toggleMatch', contact)"
        :label="__('directory.match_made.remove_match')"
      />
      <SubOption
        v-if="matched"
        @clicked="$emit('toggleBlock', contact)"
        :label="__('directory.match_made.convert_to_block')"
      />
      <SubOption
        v-if="blocked"
        @clicked="$emit('toggleBlock', contact)"
        :label="__('directory.match_made.remove_block')"
      />
      <SubOption
        v-if="blocked"
        @clicked="$emit('toggleMatch', contact)"
        :label="__('directory.match_made.convert_to_match')"
      />
    </SubOptions>
  </div>
</template>

<script>

import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';
import IconButton from '~/Components/Frontend/Core/Buttons/IconButton.vue';
import SubOption from '~/Components/Frontend/SubOption.vue';
import SubOptions from '~/Components/Frontend/SubOptions.vue';

export default {
  components: {
    ContactAvatar,
    IconButton,
    SubOption,
    SubOptions,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    matched: Boolean,
    blocked: Boolean,
  },
  emits: [
    'toggleMatch',
    'toggleBlock',
  ],
};
</script>
