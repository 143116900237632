<template>
  <div class="conference-session-row">
    <div class="conference-session-row__image">
      <img
        v-if="conference.card_image"
        :src="conference.card_image"
        :alt="conference.title"
      />
      <div class="conference-session-row__countdown" v-if="conference.starting_soon">
        <b>
          {{ __("conference_sessions.row.starting_soon") }}
          {{ conference.starting_in }}
        </b>
      </div>
    </div>
    <div class="conference-session-row__content">
      <div class="conference-session-row__sponsor" v-if="false">
        {{ __("conference_sessions.row.sponsored_by") }} <a href>Test Company LTD</a>
      </div>
      <div class="conference-session-row__tracks_container">
        <span
          v-for="track in conference.tracks"
          :style="`background-color:` + track.color + `;`"
          class="conference-session-row__track"
          :key="track.id"
        >
          <b> {{ track.label }} </b>
        </span>
      </div>
      <div class="conference-session-row__title">
        <h3 style="margin-bottom: 0">{{ conference.title }}</h3>
      </div>
      <div
        v-if="conference.short_description.trim() !== ''"
        v-html="conference.short_description"
        style="font-size: 1rem"
      >
      </div>
      <div
        v-if="conference.speakers.length > 0"
        class="conference-session-row__speaker-container"
      >
        <ContactRow
          v-for="contact in conference.speakers"
          :key="contact.id"
          :contact="contact"
          :linkable="!isIframed"
        />
      </div>
      <div class="divider"></div>
      <div class="conference-session-row__details-container">
        <span class="conference-session-row__detail">
          <i class="fa-regular fa-calendar-clock"></i>&nbsp;
          <strong>{{ conference.formatted_start_date }}</strong>
        </span>
        <span class="conference-session-row__detail">
          <strong>{{ conference.formatted_start_time }}</strong
          >&nbsp;
          <small>({{ conference.duration_for_humans }})</small>
        </span>
        <span class="conference-session-row__detail">
          <i class="fa-solid fa-location-dot"></i>&nbsp;
          {{ conference.location_name }}
          <span v-if="conference.location_details && conference.location_details.trim() !== ''">
            ({{ conference.location_details }})
          </span>
        </span>
      </div>
      <div class="button-group">
        <button v-if="loading" class="button button--loading" />
        <!-- if on demand, or online AND is running now? -->
        <a
          class="button button--primary"
          v-else-if="policy.can_enter"
          :href="`${conferenceUrl}`"
        >
          {{ __("conference_sessions.row.buttons.watch_now") }}
        </a>
        <button v-else-if="policy.is_booked_on" class="button button--primary" @click="unbook">
          <i class="fa-regular fa-calendar-circle-minus"></i>&nbsp;{{ __("conference_sessions.row.buttons.remove_from_schedule") }}
        </button>
        <button v-else-if="policy.is_busy" class="button button--primary button--disabled">
          {{ __("conference_sessions.row.buttons.schedule_clash") }}
        </button>
        <button v-else-if="!policy.has_capacity" class="button button--primary button--disabled">
         {{ __("conference_sessions.row.buttons.booking_capacity_full") }}
        </button>
        <button v-else-if="policy.can_book" class="button button--primary" @click="book">
          <i class="fa-regular fa-calendar-circle-plus"></i>&nbsp;{{ __("conference_sessions.row.buttons.add_to_schedule") }}
        </button>

        <a class="button button--secondary" :href="previewUrl">
          {{ __("conference_sessions.row.buttons.preview_session") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ContactRow from '~/Components/Frontend/CRM/ContactRow.vue';
import { store } from '../../../Frontend/store';

export default {
  name: 'ConferenceRow',
  components: { ContactRow },
  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      policy: this.model.policy,
      loading: false,
    };
  },
  methods: {
    book() {
      this.loading = true;
      axios
        .post(`${this.conferenceUrl}/book`)
        .then((response) => {
          this.emitter.emit('conference_session.booking', {
            policies: response.data.policies,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
    unbook() {
      this.loading = true;
      axios
        .post(`${this.conferenceUrl}/unbook`)
        .then((response) => {
          this.emitter.emit('conference_session.booking', {
            policies: response.data.policies,
          });
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    eventEdition() {
      return store.eventEdition;
    },
    conference() {
      return this.model;
    },
    isIframed() {
      return window.location.pathname.includes('iframe/conference-directories/');
    },
    previewUrl() {
      if (this.isIframed) {
        return `#/iframe/sessions/${this.conference.id}/preview`;
      }
      return `#/sessions/${this.conference.id}/show`;
    },
    conferenceUrl() {
      return `/${this.eventEdition?.slug}/conference-sessions/${this.conference.id}`;
    },
  },
  mounted() {
    this.emitter.on('conference_session.booking', (data) => {
      const found = data.policies.find((item) => item.conference_session_id === this.model.id);
      if (found) {
        this.policy = found;
      }
    });
  },
};
</script>
