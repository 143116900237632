<template>
  <div class="progress-bar" :style="{'--progress': progress }">
    <div class="progress-bar__label">
      <slot></slot>
    </div>
    <div class="progress-bar__bar">
      <!-- Progress bar fill -->
      <div
        :class="[
          fillClasses,
        ]"
        class="progress-bar__progress"
      ></div>

      <!-- Minimum marker -->
      <div v-if="minimum > 0" class="progress-bar__minimum" :style="{ left: minimum + '%' }"></div>

      <!-- Progress percentage tip -->
      <label
        class="progress-bar__tip"
        :style="{ color: (progress > 50 ? 'white' : 'black') }"
      >
        {{ Math.round(this.progress) }} %
      </label>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

const fillMap = {
  warning: 'progress-bar__progress--warning',
  success: 'progress-bar__progress--success',
};

export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    minimum: {
      type: Number,
      default: 0, // Minimum progress value
    },
    showTimer: {
      type: Boolean,
    },
    fill: {
      type: String,
      validator(value) {
        return Object.values(['warning', 'success']).includes(value);
      },
      default: 'success',
    },
  },
  data() {
    return {
      startTime: null,
      currentTime: null,
      elapsedTime: null,
      remainingTime: null,
    };
  },
  computed: {
    fillClasses() {
      return fillMap[this.fill];
    },
  },
  created() {
    this.startTime = moment();
  },
  watch: {
    progress(newValue, oldValue) {
      if (newValue > 0 && newValue - oldValue < 0.01) {
        return;
      }
      const elapsedTime = moment() - this.startTime;
      if (this.progress > 0) {
        this.remainingTime = ((100 - this.progress) / (this.progress)) * elapsedTime;
      }
    },
  },
};
</script>

<style>
  .progress-bar {
    --progress: 0;
    margin: 1rem;
  }

  .progress-bar__bar {
    position: relative;
    border-radius: 50px;
    background: var(--gray-2);
    overflow: hidden;
    margin-bottom: 0.5rem;
  }

  .progress-bar__progress {
    height: 16px;
    width: calc(100% * (var(--progress) / 100));
    transition: all 1s;
    border-radius: 50px;
  }

  .progress-bar__progress--success {
    background: var(--success-color);
  }

  .progress-bar__progress--warning {
    background: var(--warning-color);
  }

  .progress-bar__minimum {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: red; /* You can adjust the color */
  }

  .progress-bar__tip {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    top: 0;
    color: black;
    font-weight: bold;
  }

  .progress-bar__label {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
</style>
