<template>
  <FeLoading v-if="loading" />

  <template v-else>

    <section class="hub-section">
      <h1>{{ __('hubs.team.title') }}</h1>
      <p>{{ __('hubs.team.description') }}</p>

      <div class="fe-statistic-card__container">
        <FeStatisticCard
          colorVar="statistics-palette-2"
          icon="far fa-users"
          :header="__('hubs.team.profilesActivated')"
          :value="achievementCounts.ContactAccessedEventEdition ?? 0"
          :valueOfTotal="team.length"
        />
        <FeStatisticCard
          colorVar="statistics-palette-1"
          icon="far fa-user-tag"
          :header="__('hubs.team.contactUpdatedPlatformProfile')"
          :value="achievementCounts.ContactUpdatedPlatformProfile ?? 0"
          :valueOfTotal="team.length"
        />
        <FeStatisticCard
          colorVar="statistics-palette-4"
          icon="far fa-user-slash"
          :header="__('hubs.team.profilesHidden')"
          :value="achievementCounts.profilesHidden ?? 0"
          :valueOfTotal="team.length"
        />
      </div>

      <div class="chart-card">
        <div class="chart-card__statistic">
          <strong>{{ __('hubs.team.activityChartTitle') }}</strong>
          {{ __('hubs.team.activityChartSubtitle') }}
        </div>
        <apexchart
          v-if="teamActivityChartData[0].data.length"
          type="bar"
          height="200"
          :options="teamActivityChartOptions"
          :series="teamActivityChartData"
        />
      </div>

    </section>

    <section class="hub-section">
      <h2>{{ __('hubs.team.badgeTable') }}</h2>
      <p v-if="team.length === 0">
        {{ __('hubs.team.noOtherBadges') }}
      </p>
      <div class="hub-section__actions">
        <button
          v-if="enableTeamRegistration"
          type="button"
          class="button button--primary"
          :disabled="availableBadgesForRegistration.length === 0"
          @click="registerColleagueModel = true"
        >
          {{ __('hubs.team.register_colleague') }}
        </button>
      </div>
      <div class="profile-group">
        <div class="profile-table">
          <div class="profile-table__body list-group">
            <div
              v-for="contact in team"
              :key="contact.id"
              class="profile-table__row profile-table__row--with-status-border profile-table__row--spaced"
              :class="{
                'profile-table__row--success': contact.active,
                'profile-table__row--warning': contact.opted_out
              }"
            >
              <div class="profile-table__row-avatar">
                <ContactAvatar :contact="contact" size="small" />
              </div>
              <div class="profile-table__row-text">
                <div>
                  <div>
                    <strong>{{ contact.display_name }}</strong>,
                    {{ contact.job_title }}
                  </div>
                  <div class="chip-list">
                    <span
                      v-for="badgeLabel in contact.badge_types"
                      :key="badgeLabel"
                      class="chip chip--small"
                    >
                      {{ badgeLabel }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="profile-table__row-text--small" style="padding-right: var(--sizing-2)">
                <Popper
                  v-if="contact.impersonate_route"
                  :content="__('buttons.impersonateUser')"
                  hover
                  arrow
                >
                  <a :href="contact.impersonate_route">
                    <i class="far fa-arrow-right"></i>
                    <i class="far fa-user"></i>
                  </a>
                </Popper>
              </div>
              <div class="profile-table__row-text--small profile-table__row-text--light">
                {{ __('hubs.team.lastLogin') }}<br>
                {{ contact.last_login }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </template>

  <RegisterColleagueModal
    v-if="registerColleagueModel"
    :available-badges="availableBadgesForRegistration"
    :countries="countries"
    :default-country="activeContact.company?.country_code"
    @closed="registerColleagueModel = false"
    @load="loadData"
  >
  </RegisterColleagueModal>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Vue3Apexcharts from 'vue3-apexcharts';
import Popper from 'vue3-popper';

import { store, user } from '~/Frontend/store';
import ContactAvatar from '~/Components/Frontend/CRM/Components/ContactAvatar.vue';
import FeLoading from '~/Components/Frontend/Core/Misc/FeLoading.vue';
import FeStatisticCard from '~/Components/Frontend/Core/Misc/FeStatisticCard.vue';
// import NotificationBar from '~/Components/Frontend/NotificationBar.vue';

export default {
  name: 'EventTeamPage',
  components: {
    ContactAvatar,
    FeLoading,
    FeStatisticCard,
    apexchart: Vue3Apexcharts,
    Popper,
    // NotificationBar,
  },
  data() {
    return {
      loading: false,
      team: [],
      managedContacts: [],
      teamActivityChartOptions: {
        chart: {
          id: 'team-activity-chart',
          background: '#fff',
          offsetX: 0,
          offsetY: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: 'var(--gray-4)',
            },
          },
        },
        yaxis: {
          show: true,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      teamActivityChartData: [{
        name: 'Team activity',
        data: [],
      }],
      enableTeamRegistration: false,
      registerColleagueModel: false,
      availableBadgesForRegistration: [],
      countries: [],
    };
  },
  computed: {
    achievementCounts() {
      return _.countBy(
        this.team.flatMap((contact) => contact.achievements),
        'achievement',
      );
    },
    profilesHidden() {
      return this.team.filter((contact) => contact.opted_out).length();
    },
    activeContact() {
      return user.activeContact;
    },
    eventEdition() {
      return store.eventEdition;
    },
  },
  methods: {
    loadData() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const url = new URL(window.location);
      const ajaxUrl = `${url.pathname}/team`.replace('//', '/');

      axios.get(`${ajaxUrl}?ajax=true`).then((response) => {
        this.team = response.data.team;
        this.managedContacts = response.data.managed_contacts;
        this.teamActivityChartData[0].data = response.data.activity_chart_data.map((item) => (
          {
            x: item.day,
            y: item.active_users,
          }
        ));
        const max = Math.max(...this.teamActivityChartData[0].data.map(({ y }) => y));
        this.teamActivityChartOptions.yaxis.max = Math.round(max * 1.1, 2);
        this.enableTeamRegistration = response.data.enable_team_registration;
        this.availableBadgesForRegistration = response.data.available_badges;
        this.countries = response.data.countries;
      }).then(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
    this.loadData();
  },
};

</script>
