<template>
  <BaseModal
    :title="__('hubs.team.register_colleague')"
    ref="modal"
    startOpen
    @closed="$emit('closed')"
  >
    <div class="register-colleague">
      <div class="register-colleague__body" v-if="!form.badge_type_id">
        <ul v-if="availableBadgesOptions.length > 1" class="register-colleague__options">
          <li v-for="badge in availableBadgesOptions"
            :key="badge.value"
            class="register-colleague__option"
            @click="selectBadge(badge.value)"
            :class="{
              'register-colleague__option--selected': badge.value === form.badge_type_id,
            }"
          >
          {{ badge.text }}
          </li>
        </ul>
      </div>
      <form
        v-else
        @submit.prevent="handleSubmit"
        style="display: grid; gap: var(--sizing-2)"
      >
        <FrontendTextInput
          id="first_name"
          name="first_name"
          v-model="form.first_name"
          :label="__('hubs.team.firstName')"
          :placeholder="__('hubs.team.firstName')"
          :errors="errors.first_name"
          required
        />

        <FrontendTextInput
          id="last_name"
          name="last_name"
          v-model="form.last_name"
          :label="__('hubs.team.lastName')"
          :placeholder="__('hubs.team.lastName')"
          :errors="errors.last_name"
          required
        />

        <FrontendTextInput
          id="email"
          name="email"
          type="email"
          v-model="form.email"
          :label="__('hubs.team.email')"
          :placeholder="__('hubs.team.email')"
          :errors="errors.email"
          required
        />

        <FrontendTextInput
          id="job_title"
          name="job_title"
          type="text"
          v-model="form.job_title"
          :label="__('hubs.team.jobTitle')"
          :placeholder="__('hubs.team.jobTitle')"
          :errors="errors.job_title"
          required
        />

        <FrontendSelectInput
          id="country_code"
          name="country_code"
          v-model="form.country_code"
          :options="countryOptions"
          :label="__('hubs.team.country')"
          :placeholder="__('hubs.team.country')"
          :errors="errors.country_code"
        />

        <FrontendPhoneInput
          id="phone"
          name="phone"
          v-model="form.phone"
          :label="__('crm.phone.types.mobile')"
          :placeholder="__('crm.phone.types.mobile')"
          :errors="errors.phone"
        />
      </form>
    </div>
    <template #footer>
      <div class="button-group">
        <button
          type="button"
          class="button button--tertiary"
          @click="closeModal"
        >
          {{__('buttons.cancel')}}
        </button>
        <button
          type="button"
          class="button"
          :disabled="loading || !form.badge_type_id"
          @click="handleSubmit"
        >
          <VLoading :loading="loading" v-if="loading"></VLoading>
          <span v-else>{{__('hubs.team.register')}}</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import BaseModal from '~/Components/Frontend/BaseModal.vue';
import VLoading from '~/Components/Office/VLoading.vue';

export default {
  components: {
    BaseModal,
    VLoading,
  },
  emits: ['closed'],
  props: {
    availableBadges: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    defaultCountry: String,
  },
  data() {
    return {
      errors: {},
      loading: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        job_title: '',
        country_code: this.defaultCountry,
        phone: '',
        badge_type_id: '',
      },
    };
  },
  computed: {
    availableBadgesOptions() {
      return this.availableBadges.map((badge) => ({
        value: badge.id,
        text: `${badge.name} - Available: ${badge.available}`,
      }));
    },
    countryOptions() {
      return this.countries.map((country) => ({
        value: country.code,
        text: country.name,
      }));
    },
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
    selectBadge(badgeId) {
      this.form.badge_type_id = badgeId;
    },
    async handleSubmit() {
      this.loading = true;
      try {
        const url = new URL(window.location);
        const ajaxUrl = `${url.pathname}/team/register-colleague`.replace('//', '/');
        await axios.post(ajaxUrl, this.form, {
          headers: { 'Content-Type': 'application/json' },
        });
        this.closeModal();
        this.$emit('load');
      } catch (error) {
        if (error.response && error.response.data) {
          this.errors = error.response.data.errors || {};
        }
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.availableBadgesOptions.length === 1) {
      this.form.badge_type_id = this.availableBadgesOptions[0].value;
    }
  },
};
</script>
