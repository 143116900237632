<template>
  <div v-if="isOpen" class="base-modal__background">
    <template v-if="!loading">
      <div class="base-modal"
        :class="{
          'base-modal--wide': width === 'wide',
          'base-modal--narrow': width === 'narrow',
          'base-modal--tall': height === 'tall',
          'base-modal--short': height === 'short'
        }"
         :style="minHeight ? { minHeight } : null"
      >
        <CloseButton
          v-if="closable"
          buttonClass="base-modal__close"
          @click="close"
        />

        <div
          v-if="!loading"
          class="base-modal__header"
        >
          <slot name="header">
            <h3 v-if="title" class="base-modal__title">
              <i v-if="titleIcon" class="fa" :class="titleIcon"></i>
              {{ title }}
            </h3>
          </slot>
        </div>

        <div v-if="notifications?.length > 0">
          <div v-for="notification in notifications"
            :key="notification.time"
            class="base-modal__notification"
            :class="{
              'base-modal__notification--error': notification.type === 'error',
              'base-modal__notification--warning': notification.type === 'warning',
              'base-modal__notification--header': title,
              'base-modal__container--wide, base-modal__notification--body': !title,
            }"
          >
            <template v-if="notification.icon">
              <i class="fa-light" :class="notification.icon"></i>
            </template>
            <template v-else>
              <i class="fa-light fa-circle-exclamation"></i>
            </template>

            <template
              v-if="notification.message"
            >
            &nbsp;
            <span v-html="notification.message"></span>
            </template>
            <template v-else>
              &nbsp;{{ __('general.unknown_error') }}
            </template>
          </div>
        </div>

        <div
          :class="{'base-modal__subheader--double-padding': padding === 'double'}"
          class="base-modal__subheader"
        >
          <slot name="subheader"></slot>
        </div>

        <div class="base-modal__body"
             :class="{
              'base-modal__body--double-padding': padding === 'double',
              'base-modal__body--no-padding': padding === 'none',
              'base-modal__body--centered': centered,
            }"
             ref="baseModalBody"
             @scroll.passive="getScrollY"
        >
          <slot></slot>
        </div>

        <div class="base-modal__scroll-required" v-show="scrollRequired">
          <ScrollRequiredIcon />
        </div>

        <div class="base-modal__footer"
             :class="{
              'base-modal__footer--double-padding': padding === 'double',
              'base-modal__footer--no-padding': padding === 'none',
            }"
             v-if="hasFooter && !loading">
          <slot name="footer"></slot>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ScrollRequiredIcon from '~/Components/Frontend/ScrollRequiredIcon.vue';
import CloseButton from '~/Components/Frontend/CloseButton.vue';

export default {
  expose: ['open', 'close'],
  emits: ['opened', 'closed'],
  props: {
    title: {
      type: String,
      required: false,
    },
    titleIcon: {
      type: String,
      required: false,
    },
    contact: {
      type: Object,
      required: false,
    },
    width: {
      type: String,
      required: false,
      validator: (value) => ['wide', 'narrow'].includes(value),
    },
    padding: {
      type: String,
      required: false,
      validator: (value) => ['double', 'none'].includes(value),
    },
    height: {
      type: String,
      required: false,
      validator: (value) => ['tall', 'short'].includes(value),
    },
    closable: {
      type: Boolean,
      default: true,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Array,
      default: () => ([]),
    },
    // Center the content of the modal
    centered: {
      type: Boolean,
      default: false,
    },
    // If duration is set, close the modal after duration milleseconds
    duration: {
      type: Number,
    },
    minHeight: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
      clientHeight: 0,
      scrollHeight: 0,
      checkHeightInterval: -1,
      scrollY: 0,
      durationTimeout: null,
    };
  },
  components: { CloseButton, ScrollRequiredIcon },
  methods: {
    open() {
      this.isOpen = true;
      document.body.classList.add('modal-open');
      this.$emit('opened');
      if (this.duration) {
        this.durationTimeout = setTimeout(this.close, this.duration);
      }
    },
    close() {
      this.isOpen = false;
      document.body.classList.remove('modal-open');
      this.$emit('closed');
      if (this.durationTimeout) {
        clearTimeout(this.durationTimeout);
      }
    },
    checkHeight() {
      this.clientHeight = this.$refs.baseModalBody?.clientHeight;
      this.scrollHeight = this.$refs.baseModalBody?.scrollHeight;
      const self = this;
      this.checkHeightInterval = setTimeout(() => {
        self.checkHeight();
      }, 1000);
    },
    getScrollY(e) {
      this.scrollY = e.target.scrollTop;
    },
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
    scrollRequired() {
      return this.clientHeight < this.scrollHeight && this.scrollY < 32;
    },
  },
  mounted() {
    this.checkHeight();
    if (this.startOpen) {
      this.open();
    }
  },
  unmounted() {
    if (this.isOpen) {
      document.body.classList.remove('modal-open');
    }
    clearTimeout(this.checkHeightInterval);
  },
};
</script>
