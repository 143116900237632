<template>
    <div
      class="floorplan-page"
      :class="{
        'floorplan-page--fullscreen': fullScreenMode,
      }"
    >
      <div class="floorplan-page__container">
        <!-- floating search -->
        <div
          v-if="modalState === 'normal'"
          class="floorplan-page__search"
        >
          <div class="floorplan-page__search-input">
            <FrontendTextInput
              id="searchInputId"
              v-model="locationSearch"
              :max="500"
              hideCounter
              placeholderIcon="far fa-search"
              placeholder="Find"
              @focus="modalState = 'expanded'"
            />
          </div>
        </div>

        <!-- expanded search modal -->
        <div
          v-if="modalState === 'expanded'"
          class="floorplan-page__search floorplan-page__search--open"
        >
          <FloorplanLocationSearchList
            v-model="locationSearch"
            searchInputId="searchLocation"
            searchInputPlaceholder="Find"
            :locations="floorplan.locations"
            :selectedLocationId="selectedLocation?.id.toString() ?? ''"
            autofocus
            showCloseSearchButton
            showNavigationButton
            showPreviewButton
            @locationClicked="(location) => selectLocation(location)"
            @navigationClicked="(location) => openNavigation(null, location)"
            @close="() => modalState = 'normal'"
          />
        </div>

        <!-- navigation modal -->
        <div
          v-if="modalState === 'navigate'"
          class="floorplan-page__navigation"
        >
          <div class="floorplan-page__navigation-from-icon">
            <i class="far fa-circle"></i>
          </div>
          <div class="floorplan-page__navigation-from-holder">
            <input
              type="text"
              class="frontend-text-input"
              placeholder="Start"
              :value="navigateFrom ? `${navigateFrom.name} ${navigateFrom.number}` : ''"
              @focus="() => modalState = 'set-start'"
            />
          </div>
          <div class="floorplan-page__navigation-reverse">
            <button
              @click.stop="reverseNavigation"
              type="button"
              class="floorplan-page__navigation-reverse-button"
            >
              <i class="fas fa-arrow-up-arrow-down"></i>
            </button>
          </div>

          <div class="floorplan-page__navigation-to-icon">
            <i class="far fa-location-dot"></i>
          </div>
          <div class="floorplan-page__navigation-to-holder">
            <input
              type="text"
              class="frontend-text-input"
              placeholder="Destination"
              :value="navigateTo ? `${navigateTo.name} ${navigateTo.number}` : ''"
              @focus="() => modalState = 'set-destination'"
            />
          </div>

          <div class="floorplan-page__navigation-buttons">
            <button type="button" class="button button--secondary" @click="closeNavigation">
              <i class="far fa-arrow-left"></i> Back
            </button>
            <button
              type="button"
              class="button button--primary"
              :disabled="!navigateTo || !navigateFrom"
              @click="() => modalState = 'route'"
            >
              <i class="far fa-route"></i> Directions
            </button>
          </div>
        </div>

        <!-- set start point modal -->
        <div
          v-if="modalState === 'set-start'"
          class="floorplan-page__search floorplan-page__search--open"
        >
          <FloorplanLocationSearchList
            v-model="navigateFromSearch"
            searchInputId="navigateFromSearch"
            searchInputPlaceholder="Start"
            :locations="floorplan.locations"
            :selectedLocationId="navigateFrom?.id.toString() ?? ''"
            autofocus
            showCloseSearchButton
            @locationClicked="(location) => setStartPoint(location)"
            @close="() => modalState = 'navigate'"
          />
        </div>

        <!-- set end point modal -->
        <div
          v-if="modalState === 'set-destination'"
          class="floorplan-page__search floorplan-page__search--open"
        >
          <FloorplanLocationSearchList
            v-model="navigateToSearch"
            searchInputId="navigateToSearch"
            searchInputPlaceholder="Destination"
            :locations="floorplan.locations"
            :selectedLocationId="navigateTo?.id.toString() ?? ''"
            autofocus
            showCloseSearchButton
            @locationClicked="(location) => setEndPoint(location)"
            @close="() => modalState = 'navigate'"
          />
        </div>

        <!-- show route modal -->
        <div
          v-if="modalState === 'route'"
          class="floorplan-page__route"
        >
          <CloseButton
            buttonClass="floorplan-page__search-close"
            style="position: absolute; top: 1rem; right: 1rem"
            @click="closeRoute"
          />
          <div class="floorplan-page__route-destination">
            <i class="far fa-location-dot"></i>
            {{ navigateTo.name }} {{ navigateTo.number }}
          </div>
          <div>
            <div class="floorplan-page__route-label">
              <i class="far fa-timer"></i> Time
            </div>
            <div class="floorplan-page__route-value">
              ~ {{ routeJourneyTime }} min
            </div>
          </div>
          <div style="text-align: center;">
            <div class="floorplan-page__route-label">
              <i class="far fa-walking"></i> Distance
            </div>
            <div class="floorplan-page__route-value">
              {{ routeDistanceFormatted }}
            </div>
          </div>
          <div style="text-align: right;">
            <div class="floorplan-page__route-label">
              <i class="far fa-clock"></i> ETA
            </div>
            <div class="floorplan-page__route-value">
              {{ routeEta }}
            </div>
          </div>
        </div>

        <div
          class="floorplan-page__floorplan"
          style="position: relative;"
        >
          <FloorplanSVG
            v-if="floorplan?.id"
            ref="floorplanSVG"
            :floorplan="floorplan"
            :selectedLocation="selectedLocation"
            :lockSelected="false"
            @locationClicked="(location) => locationClicked(location)"
            @locationDblClicked="previewLocation"
            flashSelectedLocation
            :showPaths="true"
            overridePathColor="transparent"
            :activePath="activePath"
            @svgReady="zoomSoon"
          />

          <div class="floorplan-page__control-icons">
            <a class="floorplan-page__control-icon" href="#" @click.prevent="plotPathFromEntrance">
              <i class="far fa-truck"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="floorplanSVG?.zoom(3/4)">
              <i class="far fa-plus"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="floorplanSVG?.zoom(4/3)">
              <i class="far fa-minus"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="floorplanSVG?.resetViewBox">
              <i class="far fa-expand-wide"></i>
            </a>
            <a class="floorplan-page__control-icon" href="#" @click.prevent="fullScreenMode = !fullScreenMode">
              <i class="far fa-arrows-maximize"></i>
            </a>
          </div>
        </div>

      </div>
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import { store } from '~/Frontend/store';
import CloseButton from '~/Components/Frontend/CloseButton.vue';
import FloorplanLocationSearchList from '~/Components/Frontend/Floorplan/FloorplanLocationSearchList.vue';
import useCurrentTime from '~/Composables/useCurrentTime';

const props = defineProps({
  floorplanId: String,
  locationId: String,
});

/* can be
normal = mini find
expanded = search modal
navigate = directions modal
set-start = search for start point
set-destination = search for destination point
route = plotting directions
*/

const modalState = ref('normal');

const selectedLocation = ref(null);
const locationSearch = ref('');

const activePath = ref(null);
const navigateFrom = ref(null);
const navigateTo = ref(null);
const navigateFromSearch = ref('');
const navigateToSearch = ref('');

const floorplanSVG = ref(null);

const floorplan = ref(null);

const aspectRatio = ref('16 / 9');

const fullScreenMode = ref(false);

axios.get(`/${store.eventEdition.slug}/floorplan/${props.floorplanId}`)
  .then((response) => {
    floorplan.value = response.data.floorplan;
    aspectRatio.value = `${floorplan.value.width} / ${floorplan.value.depth}`;
  });

function selectLocation(location) {
  selectedLocation.value = location;
  floorplanSVG.value.zoomToLocation(location);
}

function locationClicked(location) {
  if (modalState.value !== 'normal' && modalState.value !== 'expanded') {
    return;
  }

  modalState.value = 'expanded';

  selectLocation(location);

  setTimeout(() => {
    const locElement = document.getElementById(`locationList_${location.id}`);
    locElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }, 500);
}

function previewLocation() {
  if (selectedLocation.value?.routes.preview) {
    window.location.hash = selectedLocation.value?.routes.preview;
  }
}

function zoomSoon() {
  if (props.locationId) {
    setTimeout(() => {
      const location = floorplan.value.locations.find((loc) => loc.id.toString() === props.locationId.toString());
      floorplanSVG.value.zoomToLocation(location);
      selectedLocation.value = location;
    }, 1000);
  }
}

function openNavigation(from, to) {
  if (from) {
    navigateFrom.value = from;
  }

  if (to) {
    navigateTo.value = to;
  }

  modalState.value = 'navigate';
}

function closeNavigation() {
  navigateFrom.value = null;
  navigateTo.value = null;
  activePath.value = null;
  modalState.value = 'expanded';

  setTimeout(() => {
    const locElement = document.getElementById(`locationList_${selectedLocation.value.id}`);
    locElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }, 100);
}

function setStartPoint(location) {
  navigateFrom.value = location;
  modalState.value = 'navigate';
}

function setEndPoint(location) {
  navigateTo.value = location;
  modalState.value = 'navigate';
}

function closeRoute() {
  locationSearch.value = '';
  selectedLocation.value = null;
  activePath.value = null;
  navigateFrom.value = null;
  navigateTo.value = null;
  modalState.value = 'normal';
}

function manhattenDistance(a, b) {
  return Math.abs(a.x - b.x) + Math.abs(a.y - b.y);
}

function euclideanDistance(a, b) {
  return Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
}

function key(point) {
  return `${point.x}_${point.y}`;
}

function dekey(k) {
  return { x: +k.split('_')[0], y: +k.split('_')[1] };
}

function reconstructPath(cameFrom, current) {
  let node = key(current);
  const path = [];

  do {
    path.push(dekey(node));
    node = cameFrom.get(node);
  } while (node);

  return path;
}

// eslint-disable-next-line no-unused-vars
function aStar(graph, start, goal, metric = manhattenDistance) {
  const nodes = graph.map((node) => [{ x: node.x1, y: node.y1 }, { x: node.x2, y: node.y2 }]).flat();

  // Find the closest node to start
  const startNode = nodes.reduce(
    (acc, val) => ((!acc || euclideanDistance(start, val) < euclideanDistance(acc, start)) ? val : acc),
    null,
  );

  // Find the closest node to goal
  const goalNode = nodes.reduce(
    (acc, val) => ((!acc || euclideanDistance(goal, val) < euclideanDistance(acc, goal)) ? val : acc),
    null,
  );

  const queue = new Set([key(startNode)]);
  const cameFrom = new Map();

  const gScore = new Map();
  nodes.forEach((node) => {
    gScore.set(key(node), Infinity);
  });

  const fScore = new Map();
  nodes.forEach((node) => {
    fScore.set(key(node), Infinity);
  });

  gScore.set(key(startNode), 0);
  fScore.set(key(startNode), metric(startNode, goalNode));

  let count = 0;

  while (queue.size > 0) {
    if (count > 40) return null;
    count += 1;
    let current = null;
    let minFScore = Infinity;

    queue.forEach((node) => {
      if (fScore.get(node) < minFScore) {
        current = dekey(node);
        minFScore = fScore.get(node);
      }
    });

    if (current.x === goalNode.x && current.y === goalNode.y) {
      return reconstructPath(cameFrom, current);
    }

    queue.delete(key(current));

    graph.forEach((path) => {
      let neighbor;

      if (path.x1 === current.x && path.y1 === current.y) {
        neighbor = { x: path.x2, y: path.y2 };
      } else if (path.x2 === current.x && path.y2 === current.y) {
        neighbor = { x: path.x1, y: path.y1 };
      } else {
        return;
      }

      const distance = euclideanDistance(current, neighbor);

      const tentativeGScore = gScore.get(key(current)) + distance;

      if (tentativeGScore < gScore.get(key(neighbor))) {
        cameFrom.set(key(neighbor), key(current));
        gScore.set(key(neighbor), tentativeGScore);
        fScore.set(key(neighbor), tentativeGScore + metric(neighbor, goalNode));
        if (!queue.has(key(neighbor))) {
          queue.add(key(neighbor));
        }
      }
    });
  }

  return null;
}

function reverseNavigation() {
  const temp = navigateFrom.value;

  navigateFrom.value = navigateTo.value;
  navigateTo.value = temp;
}

/**
 * Calculate the minimum and maximum bounds (x, y) based on navigate points and the active path.
 * @returns {Object} The calculated bounds: { minX, maxX, minY, maxY }.
 */
function calculateBounds() {
  const initialBounds = {
    minX: Math.min(navigateFrom.value.x, navigateTo.value.x),
    maxX: Math.max(
      navigateFrom.value.x + navigateFrom.value.width,
      navigateTo.value.x + navigateTo.value.width,
    ),
    minY: Math.min(navigateFrom.value.y, navigateTo.value.y),
    maxY: Math.max(
      navigateFrom.value.y + navigateFrom.value.depth,
      navigateTo.value.y + navigateTo.value.depth,
    ),
  };

  return activePath.value.reduce(
    (bounds, item) => ({
      minX: Math.min(bounds.minX, item.x),
      maxX: Math.max(bounds.maxX, item.x),
      minY: Math.min(bounds.minY, item.y),
      maxY: Math.max(bounds.maxY, item.y),
    }),
    initialBounds,
  );
}

function plotRoute() {
  // Both navigateFrom and navigateTo values must be set
  if (!navigateFrom.value || !navigateTo.value) {
    return;
  }

  // Calculate the active path using the A* algorithm
  activePath.value = aStar(
    floorplan.value.paths,
    {
      x: navigateFrom.value.centre_x,
      y: navigateFrom.value.centre_y,
    },
    {
      x: navigateTo.value.centre_x,
      y: navigateTo.value.centre_y,
    },
  );

  // valid path must be found
  if (!activePath.value || activePath.value.length === 0) {
    return;
  }

  // Calculate the bounds (min/max coordinates)
  const {
    minX, maxX, minY, maxY,
  } = calculateBounds();

  floorplanSVG?.value.zoomToExtents(
    minX,
    minY,
    maxY,
    maxX,
  );
}

watch(navigateFrom, async () => {
  plotRoute();
});

watch(navigateTo, async (newNavigateTo) => {
  if (!newNavigateTo) {
    return;
  }

  if (newNavigateTo.id !== selectedLocation.value.id) {
    selectedLocation.value = newNavigateTo;
  }

  plotRoute();
});

const routeDistance = computed(() => {
  let distance = 0;
  let lastItem = null;

  activePath.value.forEach((item) => {
    if (lastItem !== null) {
      distance += euclideanDistance(lastItem, item);
    }
    lastItem = item;
  });

  return Math.round(distance / floorplan.value.rounding_factor);
});

const routeDistanceFormatted = computed(() => `${routeDistance.value} ${floorplan.value.rounded_units_label}`);

// assuming 1.5minutes per hundred metres
const routeJourneyTime = computed(() => Math.max(1, Math.floor((routeDistance.value / 100) * 1.5)));

const { currentTime } = useCurrentTime();
const routeEta = computed(() => {
  const eta = new Date(currentTime.value.getTime() + (routeJourneyTime.value * 60000));
  const options = {
    timeStyle: 'short',
  };

  return new Intl.DateTimeFormat(
    document.head.querySelector('meta[name="locale-code"]'),
    options,
  ).format(eta);
});

function plotPathFromEntrance() {
  if (!selectedLocation.value) {
    return;
  }

  // top right node on path
  const start = {
    x: 1100,
    y: 3500,
  };

  const goal = {
    x: selectedLocation.value.centre_x,
    y: selectedLocation.value.centre_y,
  };

  activePath.value = aStar(floorplan.value.paths, start, goal);
}

</script>
