<template>
  <BaseModal
    ref="modal"
    width="wide"
    height="tall"
    startOpen
  >
    <div class="profile-select-modal__container">
      <template v-if="view == 'index'">
        <!-- Header -->
        <h2 class="profile-select-modal__header">
          {{ __('profile_select.header.title') }}
        </h2>
        <h5>{{ __('profile_select.header.subheader') }}</h5>
        <p>
          <button class="button-pill--primary"><b>{{ user.user.email }}</b></button>
        </p>
        <p>
          <TX k="profile_select.header.info" />
        </p>
        <div class="profile-select-modal__profiles">
          <ProfileSelectRow
            v-for="profile in profiles"
            :key="profile.id"
            :profile="profile"
            :eventEditionRoute="eventEditionRoute"
            @merge="handleMerge"
            @notYou="handleNotYou"
            />
        </div>
      </template>
      <template v-if="view == 'info'">
        <div class="profile-select-modal__info">
          <h1 class="profile-select-modal__header" style="text-align: center;">
            {{ __('profile_select.info.title') }}
          </h1>
          <h3>{{ __('profile_select.info.i_see_colleagues.title') }}</h3>
          <p>
            {{ __('profile_select.info.i_see_colleagues.description') }}
          </p>
          <p>
            <b>{{ __('profile_select.info.solution') }}</b>
            {{ __('profile_select.info.i_see_colleagues.solution_text') }}
          </p>
          <h3>{{ __('profile_select.info.duplicate_profiles.title') }}</h3>
          <p>
            {{ __('profile_select.info.duplicate_profiles.description') }}
          </p>
          <p>
            <b>{{ __('profile_select.info.solution') }}</b>
            {{ __('profile_select.info.duplicate_profiles.solution_text') }}
          </p>
          <h3> {{ __('profile_select.info.listed_different_company.title') }} </h3>
          <p>
            {{ __('profile_select.info.listed_different_company.description') }}
          </p>
          <p>
            <b> {{ __('profile_select.info.solution') }} </b>
            {{ __('profile_select.info.listed_different_company.solution_text') }}
          </p>
        </div>
      </template>
      <template v-if="view == 'merge'">
        <form @submit.prevent="merge" class="profile-select__merge">
          <h2 class="profile-select-modal__header">
            Merge Profile
          </h2>
          <p>
            Data will be merged with the <b> active profile </b>. The merged profile will <b> no longer </b> be available.
          </p>
          <ProfileSelectRow
            :profile="user.activeContact"
            :eventEditionRoute="eventEditionRoute"
            noActions
            />
          <ProfileSelectRow
            :profile="selectedProfile"
            :eventEditionRoute="eventEditionRoute"
            noActions
            >
            <span class="chip"> <i class="fa fa-merge"></i> Merge </span>
          </ProfileSelectRow>
          <p class="profile-select__warning">
            <i class="fas fa-triangle-exclamation"></i>
            Warning, this process cannot be undone.
          </p>
          <button class="button button--secondary" @click.stop="view = 'index'">
            Cancel
          </button>
          &nbsp;
          <button class="button"> Merge Profile </button>
          <p class="profile-select__warning">
            {{ mergeErrorMessage }}
          </p>
        </form>
      </template>
      <template v-if="view == 'giveaway'">
        <form @submit.prevent="giveaway" class="profile-select__not-you">
          <h2 class="profile-select-modal__header">
            Not you?
          </h2>
          <h4>
            Assign this profile to someone else
          </h4>
          <ProfileSelectRow
            :profile="selectedProfile"
            @merge="handleMerge"
            :eventEditionRoute="eventEditionRoute"
            noActions
            @notYou="handleNotYou"
            />
            <p class="profile-select__not-you-message">
              Enter the email address of the <b> new profile owner: </b>
            </p>
            <FrontendTextInput
              style="max-width: 486px; margin: auto;"
              id="giveaway-email"
              label="Email Address"
              type="email"
              v-model="giveawayForm.email"
              v-model:errors="giveawayErrors.email"
            />
            <p class="profile-select__warning">
              <i class="fas fa-triangle-exclamation"></i> Warning, you will no longer have access to this profile.
            </p>
            <button class="button button--secondary" @click.stop="view = 'index'">
              Cancel
            </button>
            &nbsp;
            <button class="button"> Assign Profile </button>
          </form>
        </template>
        <!-- Footer -->
        <div class="profile-select-modal__footer">
          <a v-if="view == 'index'" @click="view = 'info'">
            <i class="far fa-info-circle"></i>
            {{ __('profile_select.footer.multiple_profiles') }}
          </a>
          <a v-if="view == 'info'" @click="view = 'index'">
            <i class="fa-solid fa-arrow-left"></i>
            {{ __('profile_select.footer.return_to_list') }}
          </a>
        </div>
      </div>
    </BaseModal>
  </template>

<script>
import axios from 'axios';
import { user, store } from '~/Frontend/store';

export default {
  data() {
    return {
      view: 'index',
      giveawayForm: {
        email: '',
      },
      giveawayErrors: {},
      selectedProfile: null,
      mergeErrorMessage: '',
      user,
      profiles: [],
      eventEditionRoute: '',
    };
  },
  mounted() {
    const slug = store?.eventEdition?.slug;
    const url = slug ? `/${slug}/profile-select` : '/profile-select';
    axios.get(url).then((response) => {
      this.profiles = response.data.profiles;
      this.eventEditionRoute = response.data.route;
    });
  },
  methods: {
    handleNotYou(profile) {
      this.selectedProfile = profile;
      this.view = 'giveaway';
    },
    handleMerge(profile) {
      this.selectedProfile = profile;
      this.view = 'merge';
      this.mergeErrorMessage = '';
    },
    giveaway() {
      axios.post(`/contact/${this.selectedProfile.id}/giveaway`, this.giveawayForm)
        .then(() => {
          this.view = 'index';
          this.giveawayForm.email = '';
          this.popProfile(this.selectedProfile);
        }).catch(({ response }) => {
          this.giveawayErrors = response.data.errors || {};
        });
    },
    computed: {
      activeContact: () => user.activeContact,
    },
    merge() {
      axios.post(`/contact/${this.selectedProfile.id}/merge`).then(() => {
        this.view = 'index';
        this.popProfile(this.selectedProfile);
      }).catch(({ response }) => {
        this.mergeErrorMessage = response.data.message;
      });
    },
    popProfile(profile) {
      const index = this.profiles.findIndex(
        (item) => item.id === profile.id,
      );
      this.profiles.splice(index, 1);
    },
  },
};
</script>
